import styled from 'styled-components';
import { useState } from 'react';
import MultiTickerChart from './chart/multiTicker/MultiTickerChart';
import SingleTickerChart from './chart/SingleTickerChart';
import { useDataContext } from '../context/data/dataContext';
import chartTemplate from '../configure/charts/chartTemplate';

const Wrapper = styled.div`
  /* height: 500px;
  width: 700px; */
  height: 100%;
  width: 100%;
  position: relative;
  //cover .chartContainer with loading div
  .loading {
    height: 100%;
    width: 100%;
    position: absolute;
    display: ${(props) => props.displayLoading};
    left: 0rem;
    bottom: 0rem;
    z-index: 3;
    backdrop-filter: blur(2px);
  }
`;

const Chart = (props) => {
  // console.log('Chart');

  const {
    latestTimestamp,
    tickerDetails,
    tickerStats,
    lastEodHlocv,
    currentHlocv,
  } = useDataContext();
  
  const chartConfigs = chartTemplate;

  const [loading, setLoading] = useState(true);
  let displayLoading = 'none';
  if (loading) {
    displayLoading = 'block';
  }

  let tickers = props.tickers;
  const count = 1;
  if (tickers.length > count) tickers = tickers.slice(0,count);
  
  // tickers = ['TSLA'];
  // tickers = ['GOOGL'];

  if (tickers.length > 0) {
    const ticker = tickers[0];
    if (currentHlocv[ticker]) {
      // console.log(ticker, props.currentHlocv[ticker].c);
    }

  }

  return (
    <Wrapper displayLoading={displayLoading}>
      {!tickers && <div>No tickers</div>}
      {tickers.length === 1 && (
        <SingleTickerChart
          setLoading={setLoading}
          ticker={tickers}
          slotId={props.slotId}
          widgetId={props.widgetId}
          latestTimestamp={latestTimestamp}
          currentHlocv={currentHlocv}
          extendedSession={chartConfigs.extendedSession}
          showVolume={chartConfigs.showVolume}
          handleScale={chartConfigs.handleScale}
          handleScroll={chartConfigs.handleScroll}
          defaultTimeInterval={chartConfigs.defaultTimeInterval}
          timeIntervals={chartConfigs.timeIntervals}
        />
      )}
      {/* {tickers.length > 1 && (
        <MultiTickerChart
          setLoading={setLoading}
          tickers={tickers}
          slotId={props.slotId}
          widgetId={props.widgetId}
          currentHlocv={props.currentHlocv}
          extendedSession={props.extendedSession}
          showVolume={props.showVolume}
          handleScale={props.handleScale}
          handleScroll={props.handleScroll}
          defaultTimeInterval={props.defaultTimeInterval}
          timeIntervals={props.timeIntervals}
          defaultCalendarTimeframe={props.defaultCalendarTimeframe}
          calendarTimeframes={props.calendarTimeframes}
          renderData={props.renderData}
          latestTimestamp={props.latestTimestamp}
        />
      )} */}
      <div className='loading' />
    </Wrapper>
  );
};
export default Chart;
