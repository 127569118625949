import styled from 'styled-components';
import { useRef, useEffect } from 'react';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const OutsideClickCloseMenu = (props) => {
  const wrapperRef = useRef(null);

  const useHandleClickOutside = (ref) => {
    useEffect(() => {
      function handleClickOutside(event) {
        if (
          ref.current &&
          !ref.current.contains(event.target) &&
          !props.inputRef.current.contains(event.target)
        ) {
          props.setShowMenu(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref, props.inputRef]);
  };

  useHandleClickOutside(wrapperRef, props.inputRef);

  return <Wrapper ref={wrapperRef}>{props.children}</Wrapper>;
};

export default OutsideClickCloseMenu;
