import styled from 'styled-components';

const Wrapper = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;
  height: 10rem;
  overflow-y: scroll;
  align-items: center;
  .ticker {
    padding: 0.5rem;
  }
  .ticker:hover {
    cursor: pointer;
    color: red;
  }
`;

const TickersInputted = ({ settings, setSettings, listName }) => {
  const tickers = settings[listName];

  return (
    <Wrapper>
      {tickers.map((ticker) => (
        <div
          className='ticker'
          key={listName + ticker}
          onClick={() => {
            const newTickers = tickers.filter((item) => item !== ticker);
            newTickers.sort();
            setSettings({
              ...settings,
              [listName]: newTickers,
            });
          }}
        >
          {ticker}
        </div>
      ))}
    </Wrapper>
  );
};

export default TickersInputted;
