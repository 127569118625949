import { useState } from 'react';
import styled from 'styled-components';
import formatMarketCap from '../../calcs/formatMarketCap.js'

const Wrapper = styled.div`
  .inputsContainer {
    display: flex;
    flex-direction: column;
  }
  .inputContainer {
    display: flex;
    flex-direction: row;
  }
`;

//filterType: dailyQuotes, realTimeQuotes
//filterName: marketCap, move
const FiltersInput = ({ settings, filterType, filterName, updateFilter }) => {
  //inputs need to be able to be checked off so that they can be null
  //that is likely source of react console error

  let lastFilterType = settings[filterType];
  let lastFilter;
  if (lastFilterType) lastFilter = lastFilterType[filterName];

  let lastGreaterThan;
  if (lastFilter) lastGreaterThan = lastFilter.greaterThan;
  if (lastGreaterThan === undefined) lastGreaterThan = null;
  const [greaterThan, setGreaterThan] = useState(
    lastGreaterThan === null ? null : lastGreaterThan
  );

  let lastLessThan;
  if (lastFilter) lastLessThan = lastFilter.lessThan;
  if (lastLessThan === undefined) lastLessThan = null;
  const [lessThan, setLessThan] = useState(
    lastLessThan === null ? null : lastLessThan
  );

  let lastGreaterThanPercent;
  if (lastFilter) lastGreaterThanPercent = lastFilter.greaterThanPercent;
  if (lastGreaterThanPercent === undefined) lastGreaterThanPercent = null;
  const [greaterThanPercent, setGreaterThanPercent] = useState(
    lastGreaterThanPercent === null ? null : lastGreaterThanPercent
  );

  let lastLessThanPercent;
  if (lastFilter) lastLessThanPercent = lastFilter.lessThanPercent;
  if (lastLessThanPercent === undefined) lastLessThanPercent = null;
  const [lessThanPercent, setLessThanPercent] = useState(
    lastLessThanPercent === null ? null : lastLessThanPercent
  );

  //updateFilter = (newFilter, filterName)
  //filterKey: greaterThan, lessThan, greaterThanPercent, lessThanPercent
  const updateFilterState = (newValue, filterKey) => {
    //need 'newSettings' to have filterName as a key
    const newSettings = { ...settings };
    if (!newSettings[filterType]) newSettings[filterType] = {};
    if (!newSettings[filterType][filterName])
      newSettings[filterType][filterName] = {};
    if (filterName === 'marketCap') {
      if (
        filterKey === 'greaterThan' &&
        newValue !== newSettings[filterType][filterName].greaterThan
      ) {
        const typedNewValue = newValue === null ? null : Number(newValue);
        const newFilter = {
          ...newSettings[filterType][filterName],
          greaterThan: typedNewValue,
        };
        updateFilter(newFilter, filterName);
      } else if (
        filterKey === 'lessThan' &&
        newValue !== newSettings[filterType][filterName].lessThan
      ) {
        const typedNewValue = newValue === null ? null : Number(newValue);
        const newFilter = {
          ...newSettings[filterType][filterName],
          lessThan: typedNewValue,
        };
        updateFilter(newFilter, filterName);
      }
    } else {
      if (
        filterKey === 'greaterThanPercent' &&
        newValue !== newSettings[filterType][filterName].greaterThanPercent
      ) {
        const typedNewValue = newValue === null ? null : Number(newValue);
        const newFilter = {
          ...newSettings[filterType][filterName],
          greaterThanPercent: typedNewValue,
        };
        updateFilter(newFilter, filterName);
      } else if (
        filterKey === 'lessThanPercent' &&
        newValue !== newSettings[filterType][filterName].lessThanPercent
      ) {
        const typedNewValue = newValue === null ? null : Number(newValue);
        const newFilter = {
          ...newSettings[filterType][filterName],
          lessThanPercent: typedNewValue,
        };
        updateFilter(newFilter, filterName);
      }
    }
  };

  return (
    <Wrapper>
      <h5>{filterName}</h5>
      {filterName !== 'marketCap' && (
        <div className='inputsContainer'>
          <div>
            <label>Less Than Percent</label>
            <input
              type='checkbox'
              checked={lessThanPercent === null ? false : true}
              onChange={(e) => {
                if (e.target.checked) {
                  setLessThanPercent(0);
                  updateFilterState(0, 'lessThanPercent');
                } else {
                  setLessThanPercent(null);
                  updateFilterState(null, 'lessThanPercent');
                }
              }}
            />
            {lessThanPercent !== null && (
              <input
                type='number'
                value={lessThanPercent}
                onChange={(e) => {
                  if (e.target.value < 0) return;
                  setLessThanPercent(e.target.value);
                  updateFilterState(e.target.value, 'lessThanPercent');
                }}
              />
            )}
          </div>
          <div>
            <label>Greater Than Percent</label>
            <input
              type='checkbox'
              checked={greaterThanPercent === null ? false : true}
              onChange={(e) => {
                if (e.target.checked) {
                  setGreaterThanPercent(0);
                  updateFilterState(0, 'greaterThanPercent');
                } else {
                  setGreaterThanPercent(null);
                  updateFilterState(null, 'greaterThanPercent');
                }
              }}
            />
            {greaterThanPercent !== null && (
              <input
                type='number'
                value={greaterThanPercent}
                onChange={(e) => {
                  if (e.target.value < 0) return;
                  setGreaterThanPercent(e.target.value);
                  updateFilterState(e.target.value, 'greaterThanPercent');
                }}
              />
            )}
          </div>
        </div>
      )}
      {filterName === 'marketCap' && (
        <div className='inputsContainer'>
          <div className='inputContainer'>
            <label>Less Than</label>
            <input
              type='checkbox'
              checked={lessThan === null ? false : true}
              onChange={(e) => {
                if (e.target.checked) {
                  setLessThan(0);
                  updateFilterState(0, 'lessThan');
                } 
                else {
                  setLessThan(null);
                  updateFilterState(null, 'lessThan');
                } 
              }}
            />
            {lessThan !== null && (
              <input
                type='number'
                value={lessThan}
                onChange={(e) => {
                  if (e.target.value < 0) return;
                  setLessThan(e.target.value);
                  updateFilterState(e.target.value, 'lessThan');
                }}
              />
            )}
            {lessThan !== null && <div>{formatMarketCap(lessThan)}</div>}
          </div>
          <div className='inputContainer'>
            <label>Greater Than</label>
            <input
              type='checkbox'
              checked={greaterThan === null ? false : true}
              onChange={(e) => {
                if (e.target.checked) {
                  setGreaterThan(0);
                  updateFilterState(0, 'greaterThan');
                } 
                else {
                  setGreaterThan(null);
                  updateFilterState(null, 'greaterThan');
                } 
              }}
            />
            {greaterThan !== null && (
              <input
                type='number'
                value={greaterThan}
                onChange={(e) => {
                  if (e.target.value < 0) return;
                  setGreaterThan(e.target.value);
                  updateFilterState(e.target.value, 'greaterThan');
                }}
              />
            )}
            {greaterThan !== null && <div>{formatMarketCap(greaterThan)}</div>}
          </div>
        </div>
      )}
    </Wrapper>
  );
};

export default FiltersInput;
