const chartTemplate = {
  name: '',
  extendedSession: false,
  showVolume: true,
  timeScroll: false,
  priceScroll: false,
  defaultTimeInterval: 'D',
  timeIntervals: [
    {
      name: '1m',
      visibleRangeReg: (6.5 * 60) / 1, //24hrs
      // visibleRangeExt: 6.5 * 60, //24hrs
    },
    {
      name: '5m',
      visibleRangeReg: (6.5 * 60 * 2) / 5, //48hrs
    },
    {
      name: '10m',
      visibleRangeReg: (6.5 * 60 * 3) / 10, //72hrs
    },
    {
      name: '15m',
      visibleRangeReg: (6.5 * 60 * 5) / 15, //5 days
    },
    {
      name: '30m',
      visibleRangeReg: (6.5 * 60 * 10) / 30, //10 days
    },
    {
      name: '65m',
      visibleRangeReg: (6.5 * 60 * 21) / 65, //21 days
    },
    {
      name: '195m',
    },
    {
      name: 'D',
    },
    {
      name: 'W',
    },
    {
      name: 'M',
    },
  ],
  renderData: [],
};

const futureAdditions = {
  defaultCalendarTimeframe: '', //ex: 'D'
  calendarTimeframes: [], //ex: ['D', 'W', 'M', 'Q', 'Y']
  indicators: {}, //ex: ma: [50, 200], avwap: ['ath']
  priceLines: [], //ex: ath
  verticalLines: [], //example: last earnings
  renderData: [
    {
      name: 'move',
      displayName: 'Move',
      obj: 'eodCalcs',
      key: 'move',
    },
    {
      name: 'marketCap',
      displayName: 'MarketCap',
      obj: 'realTimeCalcs',
      key: 'marketCap',
    },
    {
      name: 'name',
      displayName: 'Name',
      obj: 'tickerDetails',
      key: 'name',
    },
    {
      name: 'description',
      displayName: 'Description',
      obj: 'tickerDetails',
      key: 'description',
    },
  ],
}

export default chartTemplate;
