import { useState } from 'react';
import styled from 'styled-components';
import { useConfigContext } from '../../context/config/configContext';
import { useUserContext } from '../../context/user/userContext';
import { MdModeEdit, MdOutlineDelete } from 'react-icons/md';

const Wrapper = styled.div`
  .buttonContainer {
    display: flex;
    flex-direction: row;
  }
  .layoutView {
    display: flex;
    flex-direction: row;
    width: 30rem;
    padding: 1rem;
  }
  .layoutEdit {
    display: flex;
    flex-direction: row;
    width: 30rem;
    padding: 1rem;
  }
  .layoutAction {
    display: none;
    margin: 0rem 1rem;
  }
  .layoutView:hover .layoutAction {
    display: block;
  }
`;

const convertToSeconds = (timeString) => {
  const [hours, minutes, seconds] = timeString.split(':').map(Number);
  return (hours * 60 + minutes) * 60 + seconds;
};

const sortLayoutsByStartTime = (layouts) => {
  layouts.sort((a, b) => {
    const timeA = convertToSeconds(a.startTime);
    const timeB = convertToSeconds(b.startTime);
    return timeA - timeB;
  });
  return layouts;
}

const EditDisplay = ({ displayConfig, setPage }) => {
  const { isLoading } = useUserContext();
  const { addConfig, editConfig, layouts:userLayouts } = useConfigContext();

  const [settings, setSettings] = useState(displayConfig);
  const layouts = sortLayoutsByStartTime(settings.schedule[1].layouts); //only monday for now


  const [editIndex, setEditIndex] = useState(-1);
  const [editHours, setEditHours] = useState('');
  const [editMinutes, setEditMinutes] = useState('');
  const [editSeconds, setEditSeconds] = useState('');

  const deleteLayout = (i) => {
    const newLayouts = [...layouts];
    newLayouts.splice(i, 1);
    setSettings({
      ...settings,
      schedule: [
        ...settings.schedule.slice(0, 1),
        {
          ...settings.schedule[1],
          layouts: newLayouts,
        },
        // ...settings.schedule.slice(2), //only monday for now
      ],
    });
  };

  const saveStartTime = (i) => {
    const newLayouts = [...layouts];
    //set hour minute second with padStart
    const hours = String(editHours).padStart(2, '0');
    const minutes = String(editMinutes).padStart(2, '0');
    const seconds = String(editSeconds).padStart(2, '0');
    newLayouts[i].startTime = `${hours}:${minutes}:${seconds}`;
    setSettings({
      ...settings,
      schedule: [
        ...settings.schedule.slice(0, 1),
        {
          ...settings.schedule[1],
          layouts: newLayouts,
        },
        // ...settings.schedule.slice(2), //only monday for now
      ],
    });
    setEditIndex(-1);
  };

  const saveLayout = (i, layoutId) => {
    const newLayouts = [...layouts];
    newLayouts[i].id = layoutId;
    setSettings({
      ...settings,
      schedule: [
        ...settings.schedule.slice(0, 1),
        {
          ...settings.schedule[1],
          layouts: newLayouts,
        },
        // ...settings.schedule.slice(2), //only monday for now
      ],
    });
  };

  const addLayout = () => {
    const newLayouts = [...layouts];
    newLayouts.push({
      startTime: '24:00:00',
      id: '',
    });
    setEditIndex(newLayouts.length - 1);
    setSettings({
      ...settings,
      schedule: [
        ...settings.schedule.slice(0, 1),
        {
          ...settings.schedule[1],
          layouts: newLayouts,
        },
        // ...settings.schedule.slice(2), //only monday for now
      ],
    });
  };

  const [saveMessage, setSaveMessage] = useState('');
  const handleSave = async () => {
    let res;
    console.log('settings', settings._id);
    if (settings._id) {
      res = await editConfig('display', settings);
    } else {
      res = await addConfig('display', settings);
    }
    setSaveMessage(res.msg);
    if (res.status === 1) {
      setPage('view');
    }
  };

  return (
    <Wrapper>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className='buttonContainer'>
          <button onClick={() => setPage('view')}>Cancel</button>
          <button onClick={() => handleSave()}>Save</button>
          <div>{saveMessage}</div>
        </div>
      )}
      <div>
        <label htmlFor='name'>Display Name:</label>
        <input
          type='text'
          id='name'
          value={settings.name}
          onChange={(e) => {
            setSettings({ ...settings, name: e.target.value });
          }}
        />
      </div>
      <div>
        <button onClick={() => addLayout()}>
          Add Layout
        </button>
      </div>
      {/* view layouts */}
      {layouts &&
        layouts.length > 0 &&
        layouts.map((layout, i) => (
          <div key={'layout' + i} className='layoutContainer'>
            {editIndex !== i && (
              <div className='layoutView'>
                <div>Start Time: {layout.startTime}</div>
                <div>ID: {layout.id}</div>
                <div
                  className='layoutAction'
                  onClick={() => {
                    setEditIndex(i);
                    setEditHours(Number(layout.startTime.split(':')[0]));
                    setEditMinutes(Number(layout.startTime.split(':')[1]));
                    setEditSeconds(Number(layout.startTime.split(':')[2]));
                  }}
                >
                  <MdModeEdit />
                </div>
                <div
                  className='layoutAction'
                  onClick={() => {
                    deleteLayout(i);
                  }}
                >
                  <MdOutlineDelete />
                </div>
              </div>
            )}
            {editIndex === i && (
              <div className='layoutEdit'>
                <div>Edit: </div>
                {/* input for hours */}
                <label htmlFor='hours'>Hr:</label>
                <input
                  type='number'
                  min='0'
                  max='23'
                  value={editHours}
                  onChange={(e) => {
                    setEditHours(e.target.value);
                  }}
                />
                {/* input for minutes */}
                <label htmlFor='minutes'>Min:</label>
                <input
                  type='number'
                  min='0'
                  max='59'
                  value={editMinutes}
                  onChange={(e) => {
                    setEditMinutes(e.target.value);
                  }}
                />
                {/* input for seconds */}
                <label htmlFor='seconds'>Sec:</label>
                <input
                  type='number'
                  min='0'
                  max='59'
                  value={editSeconds}
                  onChange={(e) => {
                    setEditSeconds(e.target.value);
                  }}
                />
                {/* layout options */}
                <label htmlFor='layoutSelector'>Layout</label>
                <select
                  id='layoutSelector'
                  value={userLayouts[layout.id] ? layout.id : ''}
                  onChange={(e) => {
                    console.log('e.target.value', e.target.value);
                    saveLayout(i, e.target.value);
                  }}
                >
                  <option value=''>Select Layout</option>
                  {userLayouts &&
                    Object.keys(userLayouts).length > 0 &&
                    Object.keys(userLayouts).map((layoutId, i) => (
                      <option key={'layout' + i} value={layoutId}>
                        {userLayouts[layoutId].name}
                      </option>
                    ))}
                </select>
                <button
                  onClick={() => {
                    setEditIndex(-1);
                    saveStartTime(i);
                  }}
                >
                  Save
                </button>
                <button onClick={
                  () => {
                    setEditIndex(-1);
                  }
                }
                >Cancel</button>
              </div>
            )}
          </div>
        ))}
    </Wrapper>
  );
};
export default EditDisplay;
