import styled from 'styled-components';
import round from '../../utils/round';
import formatMarketCap from '../calcs/formatMarketCap';
import { useConfigContext } from '../context/config/configContext';
import { useDataContext } from '../context/data/dataContext';

const Wrapper = styled.div`
  .row {
    display: flex;
  }
`;

const CompanyInfo = () => {
  const { ticker } = useConfigContext();
  const { currentHlocv, tickerDetails, tickerStats, lastHlocv } = useDataContext();
  const details = tickerDetails[ticker];
  const hlocv = currentHlocv[ticker];
  const move = lastHlocv[ticker] ? round((hlocv.c - lastHlocv[ticker].c) / lastHlocv[ticker].c) : '-';
  const marketCap = details.shareCount * hlocv.c;

  const stats = tickerStats[ticker];
  const correlations = stats.timeframeStats.calendar.q.correlations.stocks.d;
  //correlations is an object with keys of tickers and values of correlation coefficients
  //map to an array of tickers in descending order of correlation coefficient
  const sortedCorrelations = Object.keys(correlations).sort((a, b) => {
    return correlations[b] - correlations[a];
  });
  console.log(sortedCorrelations);

  return (
    <Wrapper>
      <div className='row'>
        <div>Name: </div>
        <div>{details.name}</div>
      </div>
      <div className='row'>
        <div>Price: </div>
        <div>{hlocv.c.toFixed(2)}</div>
      </div>
      <div className='row'>
        <div>Move: </div>
        <div>{round(move * 100) + '%'}</div>
      </div>
      <div className='row'>
        <div>Market Cap: </div>
        <div>{formatMarketCap(marketCap)}</div>
      </div>
      <div className='row'>
        <div>Description: </div>
        <div>{details.description}</div>
      </div>
      <div className='row'>
        <div>Subindustries: </div>
        <div>{details.subindustries}</div>
      </div>
      <div className='row'>
        <div>ETFs: </div>
        <div>{details.type === 'stock' ? details.etfs : details.holdings}</div>
      </div>
      <div className='row'>
        <div>Correlations: </div>
        {/* <div>{Object.keys(stats.timeframeStats.trailing.w13.correlations.stocks.d)}</div> */}
      </div>
    </Wrapper>
  );
};
export default CompanyInfo;
