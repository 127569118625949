import { listMarketOpenDates } from '../../../utils/marketStatus/marketDates';

//set latest possible start date to get maximum number of candles
const getStartDate = (timeframe) => {
  let startDate = '2018-01-01';
  //if timespan not 'minute' do nothing, keep this start date
  if (timeframe.timespan === 'minute') {
    const oldestDateSearch = '2022-01-01';
    const minCandlesInTradingDay = 16 * 60;
    const maxDays = Math.floor(50000 / minCandlesInTradingDay);
    const openMarketDays = listMarketOpenDates(oldestDateSearch);
    if (openMarketDays.length <= maxDays) {
      startDate = oldestDateSearch;
    } else {
      openMarketDays.reverse()
      startDate = openMarketDays[maxDays-1];
    }
  }
  return startDate;
};

export default getStartDate;