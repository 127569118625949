import styled from 'styled-components';
import { useEffect, useState } from 'react';
import { useApiContext } from '../context/api/apiContext';
import DateRangePicker from './DateRangePicker';
import TradeTable from './TradeTable';
import { useConfigContext } from '../context/config/configContext';

const Wrapper = styled.div`

`;

const BigTrades = () => {
  const { ticker } = useConfigContext();
  console.log('bigTrades', ticker);

  const [startDate, setStartDate] = useState('2023-02-06');
  const [endDate, setEndDate] = useState('2023-02-17');
  const [trades, setTrades] = useState();
  console.log('trades', trades);

  const { getTickerBigTrades } = useApiContext();

  useEffect(() => {
    if (!ticker || !startDate || !endDate) return;
    (async () => {
      const tempTrades = await getTickerBigTrades(ticker, startDate, endDate);
      setTrades(tempTrades);
      console.log('trades', tempTrades);
    })();
  
    return () => {}
  }, [ticker, startDate, endDate])
  

  return (
    <Wrapper>
      <DateRangePicker setStartDate={setStartDate} setEndDate={setEndDate} />
      {trades && trades.length > 0 && <TradeTable allTrades={trades} />}
    </Wrapper>
  )
}

export default BigTrades