const getSettingsDescription = (settings) => {
  let description = [];
  //only include tickers of these types
  if (settings.tickerDetails && settings.tickerDetails.type) {
    const key = {
      CS: 'Stocks',
      ETF: 'ETFs',
      ADRC: 'ADRs',
    }
    const values = [];
    settings.tickerDetails.type.forEach((type) => {
      values.push(key[type]);
    });
    if (values.length === 1) {
      description.push(`- Only include ${values[0]}. `);
    } else if (values.length === 2) {
      description.push(`- Only include ${values.join(' and ')}. `);
    } else {
      description.push(`- Include all ticker types. `);
    }
  }
  //start with these tickers
  if (settings.watchlist && settings.watchlist.length > 0) {
    description.push(`- Add ${settings.watchlist.join(', ')} to the list. `);
  }
  
  //remove these specific tickers
  if (settings.blocklist && settings.blocklist.length > 0) {
    description.push(`- Remove ${settings.blocklist.join(', ')} from list. `);
  }

  //filter out these tickers
  if (settings.dailyQuotes && typeof settings.dailyQuotes === 'object') {
    const intro = '- Only include last eod close...';
    const body = [];
    for (let filterName in settings.dailyQuotes) {
      let text = [];
      for (let filterValue in settings.dailyQuotes[filterName]) {
        const num = settings.dailyQuotes[filterName][filterValue];
        if (num && filterValue !== 'name' && filterValue !== 'active') {
          text.push(`${filterValue} ${num}`);
        }
      }
      if (text.length > 0) {
        const numText = text.length === 1 ? text[0] : text.join(' and ');
        body.push(
          `${filterName} ${numText}`
        );
      }
    }
    if (body.length > 0) {
      description.push(intro);
      body.forEach((line) => {
        description.push(`--- ${line}. `);
      });
    }
  }

  //sort this way
  if (settings.eodSort && typeof settings.eodSort === 'object') {
    for (let key in settings.eodSort) {
      if (settings.eodSort[key]) {
        description.push(
          `- ${settings.eodSort[key]} sort by last eod close ${key}. `
        );
      }
    }
  }
  if (settings.realTimeSort && typeof settings.realTimeSort === 'object') {
    for (let key in settings.realTimeSort) {
      if (settings.realTimeSort[key]) {
        description.push(
          `- ${settings.realTimeSort[key]} sort by real time ${key}. `
        );
      }
    }
  }

  //add these tickers back in no matter what
  if (settings.overrideList && settings.overrideList.length > 0) {
    description.push(`- Include ${settings.overrideList.join(', ')} no matter what. `);
  }

  return description;
};

export default getSettingsDescription;