import styled from 'styled-components';
import { useState } from 'react';
import ViewConfigs from './ViewConfigs';

const Wrapper = styled.div`
  .configureNav {
    border: 1px solid purple;
    display: flex;
    justify-content: space-between;
  }
  .configureTypeTypeNav {
    display: flex;
  }
  .configureTypeType {
    margin: 0rem 1rem;
  }
  .visualTypesContainer {
    border: 1px solid purple;
    display: flex;
    justify-content: space-around;
  }
`;

const Configure = () => {
  const [configureType, setConfigureType] = useState('visual');
  const visualTypes = ['visual', 'chart', 'list'];
  const [page, setPage] = useState('view');

  const handleTypeChange = (type) => {
    setPage('view');
    setConfigureType(type);
  }

  return (
    <Wrapper>
      <div className='configureNav'>
        <div onClick={() => handleTypeChange('display')}>Displays</div>
        <div onClick={() => handleTypeChange('layout')}>Layouts</div>
        {/* <div onClick={() => handleTypeChange('slot')}>Slots</div> */}
        <div onClick={() => handleTypeChange('visual')}>Visuals</div>
        <div onClick={() => handleTypeChange('widget')}>Widgets (Scans)</div>
      </div>
      {configureType === '' && <div>Click a configureType</div>}
      {visualTypes.includes(configureType) && (
        <div className='visualTypesContainer'>
          <div onClick={() => handleTypeChange('chart')}>Chart</div>
          <div onClick={() => handleTypeChange('list')}>List</div>
        </div>
      )}
      {configureType !== '' && configureType !== 'visual' && <ViewConfigs configureType={configureType} page={page} setPage={setPage} />}
    </Wrapper>
  );
};
export default Configure;
