import { Link } from 'react-router-dom';

const Error = () => {
  return (
    <div>
      <p>Error page!</p>
      <Link to='/login'>back to login</Link>
    </div>
  );
};

export default Error;
