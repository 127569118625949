import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
// import { createChart } from 'lightweight-charts';
import { createChart } from 'lightweight-charts/dist/lightweight-charts.esm.development';
import marketStatus from '../../../../utils/marketStatus/marketStatus';
import { getFormattedDateTodayUTC, timeToLocal } from '../../../../utils/marketStatus/dateTimeFunctions';
import isObjectEmpty from '../../../../utils/isObjectEmpty';
import { getMinuteOfSession } from '../../../../utils/marketStatus/marketDates';
import Session from '../Session';
import CandleTimeRemaining from '../CandleTimeRemaining';
import extSessionAreaSeries from '../extSessionAreaSeries';
import chartConfig from '../chartConfig';
import getAnchor from './getAnchor';
import MultiTickerTimeframes from './MultiTickerTimeframes';
import MultiTickerLegend from './MultiTickerLegend';
import { useApiContext } from '../../../context/api/apiContext';

//need to change all values to pixels
//...since this is how lightweight charts scales
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .chart {
    height: 100%;
    width: 100%;
  }
  /* .chart div table tr td:nth-child(3) div {
    width: 500px;
  } */
  .legendContainer {
    height: 0px;
    position: relative;
    color: black;
    left: 0.3rem;
    top: 0.5rem;
    z-index: 5;
    font-size: 1rem;
    font-weight: 300;
  }
  .legend {
    width: 90%;
  }
  .candleTimeframesContainer {
    height: 0px;
    width: fit-content;
    position: relative;
    color: black;
    left: 0.3rem;
    bottom: 3.2rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .candleTimeframes {
    width: fit-content;
    background-color: white;
    border: solid 1px black;
  }
  .sessionContainer {
    height: 0px;
    position: relative;
    color: black;
    left: 0.3rem;
    bottom: 4.6rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .session {
    width: fit-content;
  }
  .candleTimeRemainingContainer {
    height: 0px;
    position: relative;
    color: black;
    right: 0rem;
    bottom: 1.1rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .candleTimeRemaining {
    width: fit-content;
    margin-right: 10px;
    margin-left: auto;
    background-color: white;
    /* border: solid 1px black; */
  }
`;

const linePointTime = (timespan, timestamp) => {
  if (timespan === 'minute') {
    return timeToLocal(timestamp);
  } else if (timespan === 'week') {
    let mondayDate = new Date(timestamp);
    mondayDate.setDate(mondayDate.getDate() + 1);
    return getFormattedDateTodayUTC(mondayDate);
  } else if (timespan === 'day' || timespan === 'month') {
    return getFormattedDateTodayUTC(timestamp);
  }
  return
}

const MultiTickerChart = ({
  setLoading,
  currentHlocv,
  handleScale,
  handleScroll,
  timeIntervals,
  defaultCalendarTimeframe,
  ...props
}) => {
  // console.log('MultiTickerChart');
  const { getTimeSeries } = useApiContext();

  //handle timeframes
  const [extendedSession, setExtendedSession] = useState(props.extendedSession);
  const [timeframe, setTimeframe] = useState(() => {
    const anchor = getAnchor(defaultCalendarTimeframe);
    return {
      multiplier: anchor.multiplier,
      timespan: anchor.timespan,
      startDate: anchor.startDate,
      calendarTimeframe: defaultCalendarTimeframe,
    };
  });
  const [lastTimeframe, setLastTimeframe] = useState(timeframe);
  const [activeTimeframe, setActiveTimeframe] = useState(timeframe.timespan);
  const [startDate, setStartDate] = useState(timeframe.startDate);
  const [displayTimeframeOptions, setDisplayTimeframeOptions] = useState(false);
  const [displayTime, setDisplayTime] = useState(() => {
    if (timeframe.timespan === 'minute') return true;
    else return false;
  });
  const handleCalendarTimeframe = (calendarTimeframe) => {
    setLoading(true);
    const anchor = getAnchor(calendarTimeframe);
    setTimeframe({
      multiplier: anchor.multiplier,
      timespan: anchor.timespan,
      startDate: anchor.startDate,
      calendarTimeframe: calendarTimeframe,
    });
    setStartDate(anchor.startDate);
    if (anchor.timespan === 'minute') setDisplayTime(true);
    else setDisplayTime(false);
  };
  const percentBuffer = useRef(0.075);
  //should be equal to max candles * % buffer
  //max candles = 1d ext+reg + postmarket in min
  const rightOffsetBuffer = useRef(Math.ceil(1200 * percentBuffer.current));
  const rightOffset = useRef();

  //handle tickers
  const [tickers, setTickers] = useState(props.tickers);
  const [tickersInPlay, setTickersInPlay] = useState(props.tickers);
  const [newTickers, setNewTickers] = useState(tickers);
  const [freezeTicker, setFreezeTicker] = useState(false);
  useEffect(() => {
    if (freezeTicker) return;
    let addRemoveTicker = false;
    let addTickers = [];
    props.tickers.forEach((tick) => {
      if (!tickers.includes(tick)) {
        console.log('Adding', tick);
        addTickers.push(tick);
        addRemoveTicker = true;
      }
    });
    setTickersInPlay(tickers.concat(addTickers));
    tickers.forEach((tick) => {
      if (!props.tickers.includes(tick)) {
        console.log('Removing', tick);
        addRemoveTicker = true;
      }
    });
    if (addRemoveTicker) {
      setTickers(props.tickers);
    }
  }, [props.tickers]);

  //chart variables
  const ref = useRef();
  const chart = useRef();
  const [rawTimeSeries, setRawTimeSeries] = useState();
  const [initialtimeSeries, setInitialTimeSeries] = useState();
  const [timeSeries, setTimeSeries] = useState();
  const [lines, setLines] = useState();
  const [lastLines, setLastLines] = useState();
  const [areaPre, setAreaPre] = useState();
  const [areaPost, setAreaPost] = useState();
  const [timeSeriesAreaPre, setTimeSeriesAreaPre] = useState();
  const [timeSeriesAreaPost, setTimeSeriesAreaPost] = useState();

  //fetch timeseries data for new ticker and/or timeframe
  useEffect(() => {
    (async () => {
      let data = {};
      let allTimestamps = [];
      //obj of timestamps as keys with ticker prices
      let dataWithGaps = {};
      let dataWithoutGaps = {};
      for (let i = 0; i < tickers.length; i++) {
        //only fetch data for newly added tickers or for new timeframe
        if (
          lastTimeframe.multiplier === timeframe.multiplier &&
          lastTimeframe.timespan === timeframe.timespan &&
          timeSeries &&
          timeSeries[tickers[i]]
        ) {
          data[tickers[i]] = timeSeries[tickers[i]].slice(
            rightOffsetBuffer.current
          );
          // console.log('recycling', tickers[i], data[tickers[i]]);
        } else {
          // console.log('fetch', tickers[i]);
          data[tickers[i]] = await getTimeSeries(tickers[i], timeframe);
          if (timeframe.timespan === 'minute') {
            for (let j = 0; j < data[tickers[i]].length; j++) {
              const stamp = data[tickers[i]][j].t;
              const startDayOfMonth = new Date(startDate).getUTCDate();
              const dayOfMonth = new Date(stamp).getUTCDate();
              const status = marketStatus(stamp);
              //find the candle before the first candle of the next session
              if (
                status === 'postmarket' ||
                (status === 'premarket' && dayOfMonth !== startDayOfMonth) ||
                (status === 'open' && dayOfMonth !== startDayOfMonth)
              ) {
                data[tickers[i]] = data[tickers[i]].slice(j - 1);
                break;
              }
            }
            //INSERT LOGIC GOR PAST EVENT SUB-DAY TIMESTAMP HERE
          }
        }
        data[tickers[i]].forEach((linePoint, j) => {
          const stamp = linePoint.t;
          let value = linePoint.close || linePoint.value;
          const stampKey = 't' + String(stamp);
          if (!allTimestamps.includes(stamp)) allTimestamps.push(stamp);
          if (!dataWithGaps[stampKey]) dataWithGaps[stampKey] = {};
          dataWithGaps[stampKey][tickers[i]] = value;
        });
      }
      // console.log(dataWithGaps);
      //set first value
      //SET UNIQUE FIRST VALUES HERE
      allTimestamps.sort();
      tickers.forEach((tick) => {
        for (let i = 0; i < allTimestamps.length; i++) {
          const stampKey = 't' + String(allTimestamps[i]);
          if (dataWithGaps[stampKey][tick]) {
            dataWithoutGaps[tick] = [
              {
                t: allTimestamps[0],
                time: linePointTime(timeframe.timespan, allTimestamps[0]),
                value: dataWithGaps[stampKey][tick],
              },
            ];
            break;
          }
        }
      });
      // set all other values
      tickers.forEach((tick) => {
        for (let i = 1; i < allTimestamps.length; i++) {
          const stamp = String(allTimestamps[i]);
          const stampKey = 't' + stamp;
          if (dataWithGaps[stampKey][tick]) {
            dataWithoutGaps[tick].push({
              t: allTimestamps[i],
              time: linePointTime(timeframe.timespan, allTimestamps[i]),
              value: dataWithGaps[stampKey][tick],
            });
          } else {
            const lastStamp = String(allTimestamps[i - 1]);
            const lastStampKey = 't' + lastStamp;
            dataWithoutGaps[tick].push({
              t: allTimestamps[i],
              time: linePointTime(timeframe.timespan, allTimestamps[i]),
              value: dataWithGaps[lastStampKey][tick],
            });
          }
        }
      });
      setLastTimeframe({
        multiplier: timeframe.multiplier,
        timespan: timeframe.timespan,
      });
      setLastLines(null);
      setRawTimeSeries(dataWithoutGaps);
    })();
  }, [tickers, timeframe]);

  //process timeseries data for regular or extended session
  useEffect(() => {
    if (rawTimeSeries && !isObjectEmpty(rawTimeSeries)) {
      let sessionPriceData = {};
      //CAN ADD ext + minute => hide previous post & pre
      //to only show open + postmarketToday
      for (const ticker in rawTimeSeries) {
        sessionPriceData[ticker] = [];
        let rawTimeSeriesNoDummy;
        if (rawTimeSeries[ticker][0].d) {
          rawTimeSeriesNoDummy = rawTimeSeries[ticker].slice(rightOffsetBuffer.current)
        } else {
          rawTimeSeriesNoDummy = rawTimeSeries[ticker]
        }
        if (!extendedSession && timeframe.timespan === 'minute') {
          rawTimeSeriesNoDummy.forEach((el) => {
            if (marketStatus(el.t) === 'open') {
              sessionPriceData[ticker].push({
                t: el.t,
                time: el.time,
                value: el.value,
              });
            }
          });
        } else {
          sessionPriceData[ticker] = rawTimeSeriesNoDummy;
        }
        //calculate right offset candle count
        rightOffset.current = Math.floor(
          sessionPriceData[ticker].length * percentBuffer.current
        );
        //backfill dummy timestamps
        let dummyTimestamp = sessionPriceData[ticker][0].t;
        for (let i = 0; i < rightOffsetBuffer.current; i++) {
          const date = new Date(dummyTimestamp);
          const newDate = new Date(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate() - 1
          );
          dummyTimestamp = newDate.getTime();
          
          sessionPriceData[ticker].unshift({
            t: dummyTimestamp,
            time: linePointTime(timeframe.timespan, dummyTimestamp),
            value: sessionPriceData[ticker][0].value,
            d: 'd', //necessary to see if needs slicing above
          });
          // console.log(timeframe.timespan, sessionPriceData[ticker][0].time);
        }
      }
      setActiveTimeframe(timeframe.timespan);
      setInitialTimeSeries(sessionPriceData);
      setTimeSeries(sessionPriceData);
      setNewTickers(tickers);
    }
  }, [rawTimeSeries, extendedSession]);

  //set last lines based on currentHlocv
  useEffect(() => {
    if (lines) {
      let tempLastLines = {};
      let latestTimestamp = 0;
      for (const lineTicker in lines) {
        tempLastLines[lineTicker] = {
          value: currentHlocv[lineTicker].c,
        };
        if (currentHlocv[lineTicker].t > latestTimestamp) {
          latestTimestamp = currentHlocv[lineTicker].t;
        }
      }
      for (const lineTicker in lines) {
        tempLastLines[lineTicker]['t'] = latestTimestamp;
      }
      tempLastLines['timestamp'] = latestTimestamp;
      setLastLines(tempLastLines);
      // console.log(tempLastLines)
    }
  }, [currentHlocv]);

  //create chart with timeseries and pre/post area background
  useEffect(() => {
    if (initialtimeSeries && !isObjectEmpty(initialtimeSeries)) {
      chart.current = createChart(ref.current);
      chartConfig(
        chart.current,
        'Percentage',
        handleScale,
        handleScroll,
        extendedSession,
        activeTimeframe
      );
      if (timeframe.calendarTimeframe === 'D' || timeframe.calendarTimeframe === 'W') {
        extSessionAreaSeries(
          chart.current,
          initialtimeSeries[Object.keys(initialtimeSeries)[0]],
          extendedSession,
          activeTimeframe,
          setTimeSeriesAreaPre,
          setAreaPre,
          setTimeSeriesAreaPost,
          setAreaPost
        );
      }
      let tempLines = {};
      for (const ticker in initialtimeSeries) {
        let lineSeries = chart.current.addLineSeries({
          title: ticker,
          priceLineVisible: false,
          crosshairMarkerVisible: false,
          color: props.renderData[ticker].hexColor.vibrant,
          lineWidth: newTickers.length < 5 ? 2 : 1.25, //refine this more
        });
        let series = initialtimeSeries[ticker];
        lineSeries.setData(series);
        tempLines[ticker] = lineSeries;
      }
      //customize visible range
      const sampleTimeseries =
        initialtimeSeries[Object.keys(initialtimeSeries)[0]];
      const offSet = Math.min(rightOffset.current, rightOffsetBuffer.current);
      chart.current.timeScale().setVisibleRange({
        from: sampleTimeseries[rightOffsetBuffer.current - offSet].time,
        to: Date.now() / 1000,
      });
      chart.current.timeScale().applyOptions({
        rightOffset: offSet,
        timeVisible: displayTime,
        secondsVisible: false,
      });
      setLines(tempLines);
      setLoading(false);
      return () => {
        for (const tick in tempLines) {
          chart.current.removeSeries(tempLines[tick]);
        }
        chart.current.remove();
      };
    }
  }, [initialtimeSeries]);

  //update timeseries, pre/post area background
  useEffect(() => {
    if (timeSeries && lines && lastLines) {
      const statusNow = marketStatus(lastLines.timestamp);
      if (statusNow === 'open' || extendedSession) {
        if (
          activeTimeframe === 'day' ||
          activeTimeframe === 'week' ||
          activeTimeframe === 'month'
        ) {
          for (const lineTicker in lines) {
            lines[lineTicker].update({
              time: timeSeries[lineTicker][timeSeries[lineTicker].length - 1]
                .time,
              value: lastLines[lineTicker].value,
            });
          }
        }
        if (activeTimeframe === 'minute') {
          let previousLineSeries = timeSeries[newTickers[0]];
          let lastLineTimestamp = lastLines.timestamp;
          const interval = timeframe.multiplier;
          //has thrown error for array being undefined, cannot read length
          const previousLineTimestamp =
            previousLineSeries[previousLineSeries.length - 1].t;
          const previousLineTime =
            previousLineSeries[previousLineSeries.length - 1].time;
          const oldTime = new Date(previousLineTimestamp);
          const oldLineStartTime =
            Math.floor(getMinuteOfSession(previousLineTimestamp) / interval) *
            interval;
          const newTime = new Date(lastLineTimestamp);
          const newLineStartTime =
            Math.floor(getMinuteOfSession(lastLineTimestamp) / interval) *
            interval;
          if (oldLineStartTime === newLineStartTime) {
            let tempLines = {};
            let updatedLineSeries = timeSeries;
            for (const lineTicker in lines) {
              tempLines[lineTicker] = {
                time: previousLineTime,
                value: lastLines[lineTicker].value, //HAS THROWN ERROR
              };
              lines[lineTicker].update(tempLines[lineTicker]);
              updatedLineSeries[lineTicker][updatedLineSeries[lineTicker] - 1] =
                tempLines[lineTicker];
            }
            setTimeSeries(updatedLineSeries);
          } else if (newTime > oldTime) {
            const nextLineTimestamp = oldTime.getTime() + interval * 60 * 1000;
            const nextLineTime = timeToLocal(nextLineTimestamp);
            if (
              timeframe.calendarTimeframe === 'D' ||
              timeframe.calendarTimeframe === 'W'
            ) {
              if (extendedSession) {
                const newMinutePre = {
                  time: nextLineTime,
                  value:
                    marketStatus(lastLineTimestamp) === 'premarket'
                      ? 1000000
                      : 0,
                };
                let updatedTimeSeriesAreaPre = timeSeriesAreaPre;
                updatedTimeSeriesAreaPre.push(newMinutePre);
                setTimeSeriesAreaPre(updatedTimeSeriesAreaPre);
                areaPre.update(newMinutePre);
                const newMinutePost = {
                  time: nextLineTime,
                  value:
                    marketStatus(lastLineTimestamp) === 'postmarket'
                      ? 1000000
                      : 0,
                };
                let updatedTimeSeriesAreaPost = timeSeriesAreaPost;
                updatedTimeSeriesAreaPost.push(newMinutePost);
                setTimeSeriesAreaPost(updatedTimeSeriesAreaPost);
                areaPost.update(newMinutePost);
              }
            }
            let tempLines = {};
            let updatedLineSeries = timeSeries;
            for (const lineTicker in lines) {
              tempLines[lineTicker] = {
                t: nextLineTimestamp,
                time: nextLineTime,
                value: lastLines[lineTicker].value, //THREW ERROR IN POST
              };
              lines[lineTicker].update(tempLines[lineTicker]);
              updatedLineSeries[lineTicker].push(tempLines[lineTicker]);
            }
            //customize visible range
            const sampleTimeseries =
              updatedLineSeries[Object.keys(updatedLineSeries)[0]];
            //calculate right offset candle count
            rightOffset.current = Math.floor(
              (sampleTimeseries.length - rightOffsetBuffer.current) *
                percentBuffer.current
            );
            const offSet = Math.min(
              rightOffset.current,
              rightOffsetBuffer.current
            );
            chart.current.timeScale().setVisibleRange({
              from: sampleTimeseries[rightOffsetBuffer.current - offSet].time,
              to: Date.now() / 1000,
            });
            chart.current.timeScale().applyOptions({
              rightOffset: offSet,
              timeVisible: displayTime,
              secondsVisible: false,
            });
            setTimeSeries(updatedLineSeries);
          }
        }
      }
    }
  }, [lastLines]);

  return (
    <Wrapper>
      <div className='legendContainer'>
        <div className='legend'>
          <MultiTickerLegend
            slotId={props.slotId}
            widgetId={props.widgetId}
            tickers={newTickers}
            freezeTicker={freezeTicker}
            setFreezeTicker={setFreezeTicker}
            renderData={props.renderData}
          />
        </div>
      </div>
      <div className='chart' ref={ref} />
      <div className='candleTimeframesContainer'>
        <div className='candleTimeframes'>
          <MultiTickerTimeframes
            setTimeframe={setTimeframe}
            timeframe={timeframe}
            extendedSession={extendedSession}
            slotId={props.slotId}
            widgetId={props.widgetId}
            displayTimeframeOptions={displayTimeframeOptions}
            setDisplayTimeframeOptions={setDisplayTimeframeOptions}
            setLoading={setLoading}
            defaultCalendarTimeframe={defaultCalendarTimeframe}
            calendarTimeframes={props.calendarTimeframes}
            handleCalendarTimeframe={handleCalendarTimeframe}
          />
        </div>
      </div>
      {activeTimeframe === 'minute' && (
        <div className='sessionContainer'>
          {(timeframe.calendarTimeframe === 'D' ||
            timeframe.calendarTimeframe === 'W') && (
            <div className='session'>
              <Session
                extendedSession={extendedSession}
                setExtendedSession={setExtendedSession}
                setLoading={setLoading}
              />
            </div>
          )}
        </div>
      )}
      <div className='candleTimeRemainingContainer'>
        <div className='candleTimeRemaining'>
          <CandleTimeRemaining
            timeframe={timeframe}
            extendedSession={extendedSession}
            latestTimestamp={props.latestTimestamp}
          />
        </div>
      </div>
    </Wrapper>
  );
};;
export default MultiTickerChart;
