import styled from 'styled-components';
import getStartDate from './getStartDate';
import parseTimeInterval from './parseTimeInterval';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.1rem;
  padding-top: 0.1rem;
  .chartTimeframe {
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
  }
  ${(props) => props.css}
`;

const CandleTimeframes = ({
  setLoading,
  slotId,
  widgetId,
  timeframe,
  setTimeframe,
  extendedSession,
  displayTimeframeOptions,
  setDisplayTimeframeOptions,
  ...props
}) => {
  // console.log('Settings');

  //if ext session, do not display minute timeframes over 30 minutes
  let timeIntervals = props.timeIntervals;
  if (extendedSession) {
    timeIntervals = timeIntervals.filter((interval) => {
      const { timespan, multiplier } = parseTimeInterval(interval.name);
      if (timespan !== 'minute') {
        return true;
      } else {
        if (multiplier > 30) {
          return false;
        } else {
          return true;
        }
      }
    });
  }

  const activeTimeframeCss = 'color: blue;';
  let css = '';
  timeIntervals.forEach((interval) => {
    const { timespan, multiplier } = parseTimeInterval(interval.name);
    if (
      timespan === timeframe.timespan &&
      multiplier === timeframe.multiplier
    ) {
      css += `.${timespan + multiplier} {${activeTimeframeCss}}`;
    }
  });

  return (
    <Wrapper css={css}>
      {/* <div>test</div> */}
      {timeIntervals.map((interval) => {
        const { multiplier, timespan } = parseTimeInterval(interval.name);
        if (
          displayTimeframeOptions ||
          (timespan === timeframe.timespan &&
            multiplier === timeframe.multiplier)
        ) {
          return (
            <div
              key={
                'slot' + slotId + 'widget' + widgetId + 'chart' + interval.name
              }
              className={`noselect chartTimeframe ${timespan + multiplier}`}
              onClick={() => {
                if (
                  !(
                    timespan === timeframe.timespan &&
                    multiplier === timeframe.multiplier
                  )
                ) {
                  setLoading(true);
                  setTimeframe({
                    multiplier: multiplier,
                    timespan: timespan,
                    startDate: getStartDate({
                      multiplier: multiplier,
                      timespan: timespan,
                    }),
                  });
                  setDisplayTimeframeOptions(!displayTimeframeOptions);
                } else {
                  setDisplayTimeframeOptions(!displayTimeframeOptions);
                }
              }}
            >
              {interval.name}
            </div>
          );
        }
      })}
    </Wrapper>
  );
};
export default CandleTimeframes;
