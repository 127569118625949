const formatMarketCap = (num) => {
  if (!num) return num;
  if (num >= 1000000000000) {
    return '$' + (num / 1000000000000).toFixed(1) + 't';
  } else if (num >= 1000000000) {
    return '$' + (num / 1000000000).toFixed(1) + 'b';
  } else if (num >= 1000000) {
    return '$' + (num / 1000000).toFixed(1) + 'm';
  } else if (num >= 1000) {
    return '$' + (num / 1000).toFixed(1) + 'k';
  } else {
    return '$' + num;
  }
};

export default formatMarketCap;
