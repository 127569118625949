import styled from 'styled-components';
import { MdCircle, MdMonitor } from 'react-icons/md';
import marketStatus from '../../utils/marketStatus/marketStatus.js';

const Wrapper = styled.div`
  .marketStatusDot {
    font-size: 0.5rem;
    align-items: center;
  }
  ${(props) => props.css}
`;

const MarketStatusDot = ({ latestTimestamp }) => {
  const status = marketStatus(latestTimestamp);
  let css = '';
  if (status === 'closed') {
    css += '.marketStatusDot {color: red;}';
  } else if (status === 'premarket') {
    css += '.marketStatusDot {color: orange;}';
  } else if (status === 'open') {
    css += '.marketStatusDot {color: green;}';
  } else if (status === 'postmarket') {
    css += '.marketStatusDot {color: blue;}';
  }

  return (
    <Wrapper css={css}>
      <MdCircle className='marketStatusDot' />
    </Wrapper>
  );
};
export default MarketStatusDot;
