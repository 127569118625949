import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  .listTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const WidgetName = ({ settings, setSettings }) => {

  const handleChange = (key, value) => {
    setSettings({
      ...settings,
      [key]: value,
    });
  };

  return (
    <Wrapper>
      <h3>Name</h3>
      <input
        type='text'
        value={settings.name}
        onChange={(e) => handleChange('name', e.target.value)}
      />
    </Wrapper>
  );
};

export default WidgetName;
