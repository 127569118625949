import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ApiProvider } from './app/context/api/apiContext';
import { ConfigProvider } from './app/context/config/configContext';
import { UserProvider } from './app/context/user/userContext';
import Data from './app/Data';
import { IndexPage, Error } from './public';
import { ProtectedRoute } from './utils';
import { DataProvider } from './app/context/data/dataContext';

function App() {

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/login' element={<IndexPage />} />
        <Route
          path='/'
          element={
            <UserProvider>
              <ConfigProvider>
                <ProtectedRoute>
                  <ApiProvider>
                    <DataProvider>
                      <Data/>
                    </DataProvider>
                  </ApiProvider>
                </ProtectedRoute>
              </ConfigProvider>
            </UserProvider>
          }
        ></Route>
        <Route path='*' element={<Error />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
