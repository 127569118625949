import { useState } from 'react';
import styled from 'styled-components';
import Chart from '../../visuals/Chart';
import EditChart from './EditChart';

const ChartWrapper = styled.div`
  height: 20rem;
  width: 60rem;
  border: solid 1px grey;
`;

const Wrapper = styled.div`
`;

const EditChartContainer = ({
  latestTimestamp,
  currentHlocv,
  setPage,
  widgetConfig,
  tickerDetails,
}) => {

  //Continue with cancel/save buttons in EditChart.js

  const ticker = 'SPY';
  const [chartConfig, setChartConfig] = useState(widgetConfig);
  // console.log('chartConfig', chartConfig);
  const [previewChart, setPreviewChart] = useState(false);

  return (
    <Wrapper>
      <EditChart
        chartConfig={chartConfig}
        setChartConfig={setChartConfig}
        previewChart={previewChart}
        setPreviewChart={setPreviewChart}
        setPage={setPage}
      />
      {previewChart && (
        <ChartWrapper>
          <Chart
            tickers={[ticker]} //array of tickers
            slotId={1} //slotId integer
            widgetId={1} //widgetId integer
            currentHlocv={currentHlocv} //currentHlocv object
            extendedSession={chartConfig.extendedSession} //extendedSession boolean
            showVolume={chartConfig.showVolume} //showVolume boolean
            handleScale={chartConfig.timeScroll} //handleScale/timeScroll boolean
            handleScroll={chartConfig.priceScroll} //handleScroll/priceScroll boolean
            defaultTimeInterval={chartConfig.defaultTimeInterval} //defaultTimeInterval string ex: '1m'
            timeIntervals={chartConfig.timeIntervals} //array of timeInterval obj (name string & visible range integer)
            defaultCalendarTimeframe={chartConfig.defaultCalendarTimeframe} //timeframe string ex: 'D'
            calendarTimeframes={chartConfig.calendarTimeframes} //array of timeframes ex: ['D', 'W', 'M', 'Q', 'Y']
            renderData={''} //renderData object
            latestTimestamp={latestTimestamp} //latestTimestamp integer
          />
        </ChartWrapper>
      )}
    </Wrapper>
  );
};
export default EditChartContainer;
