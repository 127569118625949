import { useState } from 'react';
import styled from 'styled-components';
import { useConfigContext } from '../../context/config/configContext';
import { useUserContext } from '../../context/user/userContext';
import renderPreTemplate from './renderPreTemplate';
import renderOpenTemplate from './renderOpenTemplate';
import renderPostTemplate from './renderPostTemplate';
import renderClosedTemplate from './renderClosedTemplate';

const Wrapper = styled.div`
  .buttonContainer {
    display: flex;
    flex-direction: row;
  }
`;

const EditList = ({
  listConfig,
  setPage,
}) => {
  const { isLoading } = useUserContext();
  const { addConfig, editConfig } = useConfigContext();
  
  const [settings, setSettings] = useState(listConfig);
  const [renderDataName, setRenderDataName] = useState('');

  const [saveMessage, setSaveMessage] = useState('');
  const handleSave = async () => {
    let res;
    console.log('settings', settings._id);
    if (settings._id) {
      res = await editConfig('list', settings);
    } else {
      res = await addConfig('list', settings);
    }
    setSaveMessage(res.msg);
    if (res.status === 1) {
      setPage('view');
    }
  };

  return (
    <Wrapper>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className='buttonContainer'>
          <button onClick={() => setPage('view')}>Cancel</button>
          <button onClick={() => handleSave()}>Save</button>
          <div>{saveMessage}</div>
        </div>
      )}
      <div>
        <label htmlFor='name'>Chart Name:</label>
        <input
          type='text'
          id='name'
          value={settings.name}
          onChange={(e) => {
            setSettings({ ...settings, name: e.target.value });
          }}
        />
      </div>
      <div>
        <label htmlFor='renderData'>Render Data</label>
        <select
          id='renderData'
          value={renderDataName}
          onChange={(e) => {
            let renderData = [];
            if (e.target.value === 'pre') {
              renderData = renderPreTemplate;
            } else if (e.target.value === 'open') {
              renderData = renderOpenTemplate;
            } else if (e.target.value === 'post') {
              renderData = renderPostTemplate;
            } else if (e.target.value === 'closed') {
              renderData = renderClosedTemplate;
            }
            setSettings({
              ...settings,
              renderData: renderData,
            });
            setRenderDataName(e.target.value);
          }}
        >
          <option value=''>Select</option>
          <option value='pre'>Pre Market</option>
          <option value='open'>Open Market</option>
          <option value='post'>Post Market</option>
          <option value='closed'>Market Closed</option>
        </select>
      </div>
    </Wrapper>
  );
};
export default EditList;
