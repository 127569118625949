import { useState, useEffect } from 'react';
import styled from 'styled-components'
import Slot from './Slot';

const Wrapper = styled.div`
  display: grid;
  ${(props) => props.layoutAndSlotCss}
`;

const Layout = ({layoutConfigs, slotsConfigs, slotVisualsConfigs, widgetConfigs, renderContent, ...props}) => {
  // console.log('Layout');

  //set CSS
  const [layoutAndSlotCss, setLayoutAndSlotCss] = useState('');
  useEffect(() => {
    let layoutAndSlotCss = `grid-template-columns: ${layoutConfigs.gridTemplateColumns};`;
    layoutAndSlotCss += ` grid-template-rows: ${layoutConfigs.gridTemplateRows};`;
    if (slotsConfigs && slotsConfigs.length > 0) {
      slotsConfigs.forEach((slotConfigs, i) => {
        //set css for each slot
        layoutAndSlotCss += ` .slot${i} {
          grid-column: ${slotConfigs.gridColumn};
          grid-row: ${slotConfigs.gridRow};
        }`;
      });
      setLayoutAndSlotCss(layoutAndSlotCss);
    }
    return () => {
      setLayoutAndSlotCss('');
    };
  }, [layoutConfigs, slotsConfigs]);

  if (layoutAndSlotCss === '') {
    return <div>Rendering Layout</div>
  } else {
    return (
      <Wrapper layoutAndSlotCss={layoutAndSlotCss}>
        {slotsConfigs && slotsConfigs.length > 0 && slotsConfigs.map((slotConfigs, i) => {
          return (
            <div key={'slot' + i} className={'slot' + i}>
              <Slot
                slotId={i}
                slotConfig={widgetConfigs[i]}
                slotWidgetConfigs={widgetConfigs[i]}
                slotVisualsConfigs={slotVisualsConfigs[i]}
                slotTickers={renderContent.widgetTickers[i]}
                renderData={renderContent.displayData}
                currentHlocv={props.currentHlocv}
                latestTimestamp={props.latestTimestamp}
              />
            </div>
          );
        })}
      </Wrapper>
    );
  }
};
export default Layout;
