import marketStatus from '../../../utils/marketStatus/marketStatus';

const extSessionAreaSeries = (
  chart,
  initialtimeSeries,
  extendedSession,
  activeTimeframe,
  setTimeSeriesAreaPre,
  setAreaPre,
  setTimeSeriesAreaPost,
  setAreaPost
) => {
  //REQUIRES SOME PRE/POST IN VIEW TO WORK PROPERLY
  //THUS FAILS OFTEN ON MINUTE CHART WHEN EXT NOT IN VIEW
  if (extendedSession && activeTimeframe === 'minute') {
    // console.log('extSessionAreaSeries');
    //set premarket background color
    let tempTimeSeriesAreaPre = [];
    initialtimeSeries.forEach((el) => {
      tempTimeSeriesAreaPre.push({
        time: el.time,
        value: marketStatus(el.t) === 'premarket' ? 1000000 : 0,
      });
    });
    let areaSeriesPre = chart.addAreaSeries({
      topColor: 'rgba(255, 248, 235, 0.75)',
      bottomColor: 'rgba(255, 248, 235, 0.75)',
      lineColor: '#FFF8EB',
      lineWidth: '0px',
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      priceScaleId: 'left',
    });
    areaSeriesPre.setData(tempTimeSeriesAreaPre);
    setTimeSeriesAreaPre(tempTimeSeriesAreaPre);
    setAreaPre(areaSeriesPre);
    //set postmarket background color
    let tempTimeSeriesAreaPost = [];
    initialtimeSeries.forEach((el) => {
      tempTimeSeriesAreaPost.push({
        time: el.time,
        value: marketStatus(el.t) === 'postmarket' ? 1000000 : 0,
      });
    });
    let areaSeriesPost = chart.addAreaSeries({
      topColor: 'rgb(238, 247, 255, 0.75)',
      bottomColor: 'rgb(238, 247, 255, 0.75)',
      lineColor: '#EEF7FF',
      lineWidth: '0px',
      priceLineVisible: false,
      crosshairMarkerVisible: false,
      priceScaleId: 'left',
    });
    areaSeriesPost.setData(tempTimeSeriesAreaPost);
    setTimeSeriesAreaPost(tempTimeSeriesAreaPost);
    setAreaPost(areaSeriesPost);
  }
};

export default extSessionAreaSeries;