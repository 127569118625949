import styled from 'styled-components';
import { useState } from 'react';
import Navbar from './navbar/Navbar';
import Ticker from './ticker/Ticker';
import { useConfigContext } from './context/config/configContext';
import Layout from './hub/Layout';
import Configure from './configure/Configure';
import Watchlists from './watchlists/Watchlists';

const Wrapper = styled.div`
`;

const Home = ({ latestTimestamp }) => {
  console.log('Home rendered')
  const { appPage, ticker } = useConfigContext();

  return (
    <div>
      <Navbar
        latestTimestamp={latestTimestamp}
      />
      {appPage === 'home' && <div>Go to hub or enter a ticker</div>}
      {!ticker && appPage === 'ticker' && <div>Enter a ticker</div>}
      {ticker && appPage === 'ticker' && (
        <Ticker/>
      )}
      {/* market will serve as a market wide browsing appPage */}
      {/* with the dual functionality as serving as a shopping cart for the hub */}
      {/* {appPage === 'market' && <div>market</div>} */}
      {appPage === 'watchlists' && (
        <Watchlists/>
      )}
      {appPage === 'configure' && (
        <Configure/>
      )}
    </div>
  );
};

export default Home;
