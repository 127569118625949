import { useState } from 'react';
import styled from 'styled-components';
import formatMarketCap from '../../calcs/formatMarketCap.js';

const Wrapper = styled.div`
  .inputsContainer {
    display: flex;
    flex-direction: column;
  }
  .inputContainer {
    display: flex;
    flex-direction: row;
  }
`;

//sortType: dailyQuotes, realTimeQuotes
//sortName: marketCap, move
const SortInput = ({ settings, sortType, sortName, updateSort }) => {
  const setting = settings[sortType][sortName];
  const isSetting = setting === null || setting === undefined ? false : true;

  return (
    <Wrapper>
      {/* <h5>{sortName}</h5> */}
      <div className='inputsContainer'>
        <div className='inputContainer'>
          <label>{sortName}</label>
          <input
            type='checkbox'
            checked={isSetting ? true : false}
            onChange={(e) => {
              if (e.target.checked) {
                updateSort('desc', sortName);
              } else {
                updateSort(null, sortName);
              }
            }}
          />
          {isSetting && (
            <select
              value={isSetting ? setting : 'desc'}
              onChange={(e) => updateSort(e.target.value, sortName)}
            >
              <option value='asc'>Ascending</option>
              <option value='desc'>Descending</option>
            </select>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default SortInput;
