import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfigContext } from '../context/config/configContext';
import { MdMonitor, MdReorder } from 'react-icons/md';


const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  .appPage {
    display: flex;
    align-items: center;
  }
  .logout {
    display: flex;
    align-items: center;
  }
  .appPage,
  .logout {
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    text-align: left;
  }
  .dropdownContainer .logout {
    border-top: 1px solid #ddd;
  }
  .displaysHeader {
    border-bottom: 1px solid #ddd;
  }
  .dropdown {
    cursor: pointer;
  }
  .navigateDropdownContainer {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 6rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  .navigateContainer:hover .navigateDropdownContainer {
    display: block;
  }
  .displaysDropdownContainer {
    display: none;
    position: absolute;
    right: 0;
    background-color: #f9f9f9;
    min-width: 6rem;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    z-index: 10;
  }
  .displaysContainer:hover .displaysDropdownContainer {
    display: block;
  }

  ${(props) => props.css}
`;

const Navigate = ({ latestTimestamp }) => {
  const { logout } = useAuth0();
  const { setAppPage, setActiveDisplay, displays } = useConfigContext();

  let css = '';

  return (
    <Wrapper css={css}>
      <div className='displaysContainer'>
        <MdMonitor className='displaysDropdown' />
        <div className='displaysDropdownContainer'>
          <div className='appPage displaysHeader'>Displays</div>
          {displays &&
            Object.keys(displays).length > 0 &&
            Object.keys(displays).map((displayId) => (
              <div
                key={'displaySelect' + displayId}
                className='appPage display'
                onClick={() => setActiveDisplay(displayId)}
              >
                {displays[displayId].name}
              </div>
            ))}
        </div>
      </div>
      <div className='navigateContainer'>
        <MdReorder className='navigateDropdown' />
        <div className='navigateDropdownContainer'>
          <div className='appPage ticker' onClick={() => setAppPage('ticker')}>
            Ticker
          </div>
          <div className='appPage hub' onClick={() => setAppPage('watchlists')}>
            Watchlists
          </div>
          <div className='appPage hub' onClick={() => setAppPage('hub')}>
            Hub
          </div>
          <div
            className='appPage configure'
            onClick={() => setAppPage('configure')}
          >
            Configure
          </div>
          <div className='logout' onClick={() => logout()}>
            Logout
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
export default Navigate;
