import styled from 'styled-components';
import { useState } from 'react';
import OptionsChain from './OptionsChain';
import CompanyInfo from './CompanyInfo';
import BigTrades from './BigTrades';
import ChartContainer from './ChartContainer';
import { useDataContext } from '../context/data/dataContext';
import Chart from '../visuals/Chart';

const Wrapper = styled.div`
  .tickerNav {
    border: 1px solid purple;
    display: flex;
    justify-content: space-between;
  }
`;

const Ticker = () => {
  const { latestTimestamp, currentHlocv, tickerDetails, lastEodHlocv } = useDataContext();

  const [page, setPage] = useState('');
  //in info/summary, include company info, summary of other pages (top trades, skew, etc)

  //need to get individual ticker data stream in here without making renderContent bigger

  return (
    <Wrapper>
      <div className='tickerNav'>
        <div onClick={() => setPage('info')}>Company Info</div>
        <div onClick={() => setPage('chart')}>Chart</div>
        <div onClick={() => setPage('bigTrades')}>Big Trades</div>
        <div onClick={() => setPage('optionsChain')}>Option Chain</div>
        <div onClick={() => setPage('unusualOptionsActivity')}>
          Unusual Options Activity
        </div>
      </div>
      {page === '' && <div>Click a page</div>}
      {page === 'info' && (
        <CompanyInfo
          currentHlocv={currentHlocv}
          tickerDetails={tickerDetails}
          lastEodHlocv={lastEodHlocv}
        />
      )}
      {page === 'chart' && (
        <Chart
          latestTimestamp={latestTimestamp}
        />
      )}
      {page === 'bigTrades' && <BigTrades />}
      {page === 'optionsChain' && <OptionsChain />}
      {page === 'unusualOptionsActivity' && <div>Coming soon</div>}
    </Wrapper>
  );
};
export default Ticker;