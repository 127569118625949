import styled from 'styled-components';
import TickerInput from './TickerInput';
import DateTime from './DateTime';
import Navigate from './Navigate';

const Wrapper = styled.div`
  width: 100%;
  /* height: 2rem; */
  display: grid;
  grid-template-columns: 12rem 1fr auto 1fr 8rem 3rem 1rem;
  align-items: center;
  .tickerInput {
    grid-column: 1;
  }
  .dateTime {
    grid-column: 3;
  }
  .navigate {
    grid-column: 6;
  }

  ${(props) => props.css}
`;

const Navbar = ({ latestTimestamp }) => {

  let css = '';

  return (
    <Wrapper css={css}>
      <div className='tickerInput'>
        <TickerInput />
      </div>
      <div className='dateTime'>
        <DateTime latestTimestamp={latestTimestamp} />
      </div>
      <div className='navigate'>
        <Navigate />
      </div>
    </Wrapper>
  );
};
export default Navbar;
