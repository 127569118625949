import styled from 'styled-components';
import round from '../../utils/round';

const Wrapper = styled.div`
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }

  th,
  td {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }

  th {
    background-color: #dddddd;
  }
`;


const TradeTable = ({ allTrades }) => {
  console.log('allTrades', allTrades);
  return (
    <Wrapper>
      <h2>Trades</h2>
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>ID</th>
            <th>Size</th>
            <th>Price</th>
            <th>Dollar</th>
          </tr>
        </thead>
        <tbody>
          {allTrades.map((tradeGroup) => {
            console.log('tradeGroup', tradeGroup);
            return tradeGroup.trades.map((trade) => {
              if (
                trade.conditions &&
                trade.conditions.length > 0 &&
                (trade.conditions.includes(15) || trade.conditions.includes(16))
              ) {
                return null;
              }
              let dollars = round(trade.dollars, 2);
              return (
                <tr key={trade.id}>
                  <td>{tradeGroup.date}</td>
                  <td>{trade.id}</td>
                  <td>{trade.size.toLocaleString()}</td>
                  <td>{trade.price}</td>
                  <td>{'$' + dollars.toLocaleString()}</td>
                </tr>
              );
            });
          })}
        </tbody>
      </table>
    </Wrapper>
  );
};

export default TradeTable;
