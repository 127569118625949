import styled from 'styled-components';
import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useConfigContext } from '../context/config/configContext';
import { MdMonitor } from 'react-icons/md';
import TickerInput from './TickerInput';
import marketStatus from '../../utils/marketStatus/marketStatus.js';
import Lag from './Lag';
import MarketStatusDot from './MarketStatusDot';

const Wrapper = styled.div`
  .marketStatusContainer {
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
  }
  .marketStatusDot {
    font-size: 0.5rem;
    align-items: center;
  }
  .dateContainer {
    display: flex;
    align-items: center;
  }
  ${(props) => props.css}
`;

const DateTime = ({ latestTimestamp }) => {
  const status = marketStatus(latestTimestamp);
  let date = new Date(latestTimestamp);
  if (status === 'closed') {
    date = new Date();
  }

  const [isLag, setIsLag] = useState(false);
  const displayDate = date
    .toDateString()
    .substring(0, date.toDateString().length - 5);
  let tempHours = date.getHours();
  if (tempHours > 12) {
    tempHours -= 12;
  }
  const hours = String(tempHours).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');
  const amPm = date.getHours() >= 12 ? 'PM' : 'AM';
  let displayTime;
  if (status === 'closed') {
    displayTime = hours + ':' + minutes + ' ' + amPm;
  } else {
    displayTime = hours + ':' + minutes + ':' + seconds + ' ' + amPm;
  }
  let css = '';
  if (isLag) {
    css += '.date {color: red;}';
  }

  return (
    <Wrapper css={css}>
      <div className='dateContainer'>
        <div className='marketStatusContainer'>
          <MarketStatusDot latestTimestamp={latestTimestamp} />
        </div>
        <div className='date'>
          {displayDate + ' '}&bull;{' ' + displayTime}
          {status === 'closed' ? (
            ''
          ) : (
            <Lag latestTimestamp={latestTimestamp} setIsLag={setIsLag} />
          )}
        </div>
      </div>
    </Wrapper>
  );
};
export default DateTime;
