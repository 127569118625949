import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 2rem;
  .listName {
    font-size: 1.25rem;
  }
  .tickerList {
  }
  .tickerListItem {
    font-size: 1rem;
    display: grid;
    grid-template-columns: 3rem 22rem;
  }
  .tickerName {
    grid-column: 1;
  }
  .tickerRenderData {
    grid-column: 2;
    font-size: 1rem;
    display: grid;
    grid-template-columns: 5rem 5rem 12rem;
  }
  .tickerLast {
    justify-self: right;
    grid-column: 1;
  }
  .tickerMove {
    justify-self: right;
    grid-column: 2;
  }
  .tickerIndustry {
    font-size: 0.6rem;
    padding-left: 0.5rem;
    align-self: center;
    grid-column: 3;
  }
  .highlight {
    color: blue;
    font-weight: bold;
  }
`;

const List = ({slotId, widgetId, name, configs, renderData, ...props}) => {
  // console.log('List')
  // console.log(renderData)
  let tickers = props.tickers;
  const count = 500;
  if (tickers.length > count) tickers = tickers.slice(0, count);
  
  return (
    <Wrapper>
      <div className='listName'>{name}</div>
      <br />
      {!tickers && <div>No tickers yet</div>}
      {tickers && (<div className='tickerList'>
        {tickers.map((ticker, i) => {
          let highlight = '';
          if (configs.highlight.includes(ticker)) {
            highlight = ' highlight';
          } else {
            highlight = '';
          }
          return (
            <div
              key={'slot' + slotId + 'widget' + widgetId + ticker}
              className={'tickerListItem'}
            >
              <div className={`tickerName${highlight}`}>{ticker}</div>
              <div className={'tickerRenderData'}>
                {configs.renderData.map((column, i) => {
                  return (
                    <div
                      key={'slot' + slotId + 'widget' + widgetId + ticker + 'renderDataColumn' + i}
                      className={`ticker${column.displayName}${highlight}`}
                    >
                      {renderData[ticker][column.name]}
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>)}
    </Wrapper>
  );
};
export default List;
