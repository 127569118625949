import { CrosshairMode, PriceScaleMode } from 'lightweight-charts';

const chartConfig = (
  chart,
  mode,
  handleScale,
  handleScroll,
  extendedSession,
  activeTimeframe
) => {
  let scaleMarginTop = 0.9;
  if (extendedSession && activeTimeframe === 'minute') {
    scaleMarginTop = 0;
  } 
  // console.log(activeTimeframe, scaleMarginTop)
  chart.applyOptions({
    handleScale: handleScale,
    handleScroll: handleScroll,
    crosshair: {
      mode: CrosshairMode.Normal,
    },
    rightPriceScale: {
      mode: PriceScaleMode[mode],
      // mode: PriceScaleMode['Logarithmic'],
      entireTextOnly: true,
      scaleMargins: {
        top: 0.2,
        bottom: 0.1,
      },
      drawTicks: false,
    },
    leftPriceScale: {
      visible: false,
      scaleMargins: {
        // top: extendedSession ? 0 : 0.9,
        top: scaleMarginTop,
        bottom: 0,
      },
    },
    grid: {
      horzLines: {
        color: 'rgba(214, 220, 222, 0.5)',
        // visible: false,
      },
      vertLines: {
        color: 'rgba(214, 220, 222, 0.5)',
        // color: '#ffffff',
        // visible: false,
      },
    },
  });
};

export default chartConfig;