import styled from 'styled-components';
import SortInput from './SortInput';

const Wrapper = styled.div`
  /* display: block; */
`;

const TickerSorts = ({
  settings,
  setSettings,
  sortType,
  sortTypes,
  sortOptions,
}) => {

  const updateSort = (newSort, sortName) => {
    //first delete all other sorts
    const newSettings = settings;
    sortTypes.forEach((type) => {
      for (let key in newSettings[type]) {
        delete newSettings[type][key];
      }
    });
    let sorts = {
      [sortName]: newSort,
    };
    setSettings({
      ...newSettings,
      [sortType]: sorts,
    });
  };

  return (
    <Wrapper>
      <h3>{sortType}</h3>
      {sortOptions.map((sortName) => (
        <div key={sortType + sortName}>
          <SortInput
            settings={settings}
            sortType={sortType}
            sortName={sortName}
            updateSort={updateSort}
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default TickerSorts;
