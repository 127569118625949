const renderPreTemplate = [
  {
    name: 'last',
    displayName: 'Last',
    obj: 'preMarketHlocv',
    key: 'c',
  },
  {
    name: 'move',
    displayName: 'Move',
    obj: 'realTimeCalcs',
    key: 'preMarketMove',
  },
];

export default renderPreTemplate;