import styled from 'styled-components';
import { useDataContext } from '../../context/data/dataContext';

const Wrapper = styled.div`
  /* border: solid 1px black; */
  .hlocv {
    background-color: white;
    font-size: 0.75rem;
  }
  .hlocvColor {
    color: ${(props) => props.hlocvColor};
  }
  .moves {
    margin-left: auto;
    margin-right: 0;
    font-size: 0.75rem;
    text-align: right;
  }
  .movesColor {
    color: ${(props) => props.tickerMoveCSS};
  }
`;

const formatVolume = (volume) => {
  if (!volume) return volume;
  if (volume > 1000000000) {
    return (volume / 1000000000).toFixed(1) + 'b';
  } else if (volume > 1000000) {
    return (volume / 1000000).toFixed(1) + 'm';
  } else if (volume > 1000) {
    return (volume / 1000).toFixed(1) + 'k';
  } else {
    return volume;
  }
}

// add move on day and compared to last candle on hover
const Stats = ({ ticker, lastHlocv, hoverHlocv }) => {
  // console.log('Legend');
  let high = lastHlocv && (lastHlocv.h).toFixed(2);
  let low = lastHlocv && lastHlocv.l.toFixed(2);
  let open = lastHlocv && lastHlocv.o.toFixed(2);
  let close = lastHlocv && lastHlocv.c.toFixed(2);
  let volume = lastHlocv && formatVolume(lastHlocv.v);
  let hlocvColor = lastHlocv && lastHlocv.color;
  if (hoverHlocv) {
    high = hoverHlocv && hoverHlocv.h.toFixed(2);
    low = hoverHlocv && hoverHlocv.l.toFixed(2);
    open = hoverHlocv && hoverHlocv.o.toFixed(2);
    close = hoverHlocv && hoverHlocv.c.toFixed(2);
    volume = hoverHlocv && formatVolume(hoverHlocv.v);
    // hlocvColor = hoverHlocv && hlocvColor;
  }

  const { lastEodHlocv: allLastEodHlocv } = useDataContext();
  const lastEodHlocv = allLastEodHlocv[ticker];
  const move = lastEodHlocv && lastHlocv && ((close - lastEodHlocv.c) / lastEodHlocv.c * 100).toFixed(2) + '%';

  let tickerMoveCSS = 'grey';
  if (move) {
    if (move.substring(0, move.length - 1) > 0) {
      tickerMoveCSS = '#26A69A;';
    } else if (move.substring(0, move.length - 1) < 0) {
      tickerMoveCSS = '#EF5350;';
    }
  }

  return (
    <Wrapper tickerMoveCSS={tickerMoveCSS} hlocvColor={hlocvColor}>
      <div className='hlocv'>
        {lastHlocv && (
          <span>
            H: <span className='hlocvColor'>{high + ' '}</span>
            L: <span className='hlocvColor'>{low + ' '}</span>
            O: <span className='hlocvColor'>{open + ' '}</span>
            C: <span className='hlocvColor'>{close + ' '}</span>
            V: <span className='hlocvColor'>{volume}</span>
          </span>
        )}
      </div>
      <div className='moves'>
        <span>
          {/* D: <span className='movesColor'>{move}</span> */}
        </span>
      </div>
    </Wrapper>
  );
};
export default Stats;
