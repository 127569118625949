import { useState } from 'react';
import styled from 'styled-components';
import { useConfigContext } from '../../context/config/configContext';
import { useUserContext } from '../../context/user/userContext';
import EditGrid from './EditGrid';


const Wrapper = styled.div`
  .buttonContainer {
    display: flex;
    flex-direction: row;
  }
`;

const EditLayout = ({ layoutConfig, setPage }) => {
  console.log('layoutConfig', layoutConfig);
  const { isLoading } = useUserContext();
  const { addConfig, editConfig } = useConfigContext();

  const [settings, setSettings] = useState(layoutConfig);

  const [saveMessage, setSaveMessage] = useState('');
  const handleSave = async (tempSettings) => {
    let res;
    console.log('settings', tempSettings._id);
    if (tempSettings._id) {
      res = await editConfig('layout', tempSettings);
    } else {
      res = await addConfig('layout', tempSettings);
    }
    console.log('res', res);
    setSaveMessage(res.msg);
    if (res.status === 1) {
      setPage('view');
    }
  };

  return (
    <Wrapper>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <EditGrid //feed in save msg
          settings={settings}
          setSettings={setSettings}
          setPage={setPage}
          handleSave={handleSave}
          saveMessage={saveMessage}
          setSaveMessage={setSaveMessage}
        />
      )}
    </Wrapper>
  );
};
export default EditLayout;
