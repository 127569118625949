import styled from 'styled-components';
import Type from './Type';

const Wrapper = styled.div`

`;

const TickerDetails = ({ settings, setSettings, possibleTypes }) => {
  const updateDetails = (newTickerDetail, detailName) => {
    let tickerDetails = settings.tickerDetails;
    if (!tickerDetails) tickerDetails = {};
    tickerDetails[detailName] = newTickerDetail;
    setSettings({
      ...settings,
      tickerDetails,
    });
  };

  return (
    <Wrapper>
      <Type
        tickerDetails={settings.tickerDetails}
        updateDetails={updateDetails}
        possibleTypes={possibleTypes}
      />
    </Wrapper>
  );
};

export default TickerDetails;
