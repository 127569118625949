const round = (num, decimalPlaces) => {
  if (num === 0) {
    return 0;
  } else {
    let decPlaces = 4;
    if (decimalPlaces) {
      decPlaces = decimalPlaces;
    }
    const mag = Math.pow(10, decPlaces);
    return Math.round(num * mag) / mag;
  }
}

export default round;