import styled from 'styled-components';
import { useApiContext } from '../context/api/apiContext'
import { useState, useEffect } from 'react';
import { usePublicContext } from '../../context/publicContext';

const Wrapper = styled.div`
  border: 1px solid blue;
  padding: 1rem;
  .configs {
    margin: 0 0.5rem 0.5rem 0;
    display: flex;
    font-size: 1.5rem;
  }
  .type {
    margin-right: 1rem;
    text-transform: capitalize;
  }
  .expiryList {
    font-size: 1.25rem;
    width: 10rem;
  }
  table {
    font-size: 1.25rem;
    border-collapse: collapse;
    scrollbar-width: none;
  }
  thead {
    display: table;
    table-layout: fixed;
  }
  tbody {
    display: block;
    overflow: auto;
    max-height: 25rem;
  }
  table thead tr td {
    border-bottom: 1px solid black;
  }
  .header .expiry {
    font-size: 1.25rem;
    width: 7rem;
  }
  .header .bid {
    text-align: center;
  }
  .header .ask {
    text-align: center;
  }
  .expiry {
    font-size: 1rem;
    width: 7rem;
  }
  .strike {
    width: 4rem;
  }
  .bid {
    width: 6rem;
    padding-right: 1rem;
    text-align: right;
  }
  .ask {
    width: 6rem;
    padding-right: 1rem;
  }
  .bidAskSize {
    font-size: 0.75rem;
  }
  .volume {
    width: 5rem;
    text-align: right;
  }
  .oi {
    width: 6rem;
    text-align: right;
  }
  .iv {
    width: 6rem;
    text-align: right;
  }
  //justify table items to the right
`;

const OptionsChain = () => {
  // console.log('OptionsChain rendered')
  const [loadedTicker, setLoadedTicker] = useState('noTicker');
  const { ticker } = usePublicContext();
  const { getOptionsChain } = useApiContext();
  const [callPut, setCallPut] = useState('calls');
  const [chain, setChain] = useState();
  const [expiryIndex, setExpiryIndex] = useState(0)
  
  useEffect(() => {
    if (!ticker) return;
    (async () => {
      console.log(ticker)
      const data = await getOptionsChain(ticker);
      setChain(data);
      setExpiryIndex(0);
      setLoadedTicker(ticker);
    })();
  }, [ticker]);

  return (
    <Wrapper>
      <div>view chain</div>
      <div>noteable contracts</div>
      {ticker === loadedTicker && chain && (
        <div className='configs'>
          <div
            className='type'
            onClick={() => {
              if (callPut === 'calls') setCallPut('puts');
              else setCallPut('calls');
            }}
          >
            {callPut}
          </div>
          <div>
            <select
              className='expiryList'
              value={expiryIndex}
              onChange={(event) => setExpiryIndex(event.target.value)}
            >
              {chain &&
                chain.map((expiryChain, index) => (
                  <option
                    key={index}
                    //should clean up to properly set value
                    value={index}
                  >
                    {expiryChain.expiry}
                  </option>
                ))}
            </select>
          </div>
        </div>
      )}
      {!ticker && <div>Enter a ticker to view it's option chain</div>}
      {ticker && ticker !== loadedTicker && <div>Loading chain</div>}
      {ticker === loadedTicker && chain.length === 0 && <div>[no data]</div>}
      {ticker === loadedTicker && chain && chain.length > 0 && (
        <div>
          <table>
            <thead>
              <tr className='header'>
                <td className='expiry'>Expiry</td>
                <td className='strike'>Strike</td>
                <td className='bid'>Bid</td>
                <td className='ask'>Ask</td>
                <td className='volume'>Volume</td>
                <td className='oi'>OI</td>
                <td className='iv'>IV</td>
              </tr>
            </thead>
            <tbody>
              {chain[expiryIndex][callPut].map((item, index) => {
                const bidSize = item.quote.bid_size ? item.quote.bid_size : '0';
                const askSize = item.quote.ask_size ? item.quote.ask_size : '0';
                const bid = item.quote.bid ? item.quote.bid.toFixed(2) : '0';
                const ask = item.quote.ask ? item.quote.ask.toFixed(2) : '0';
                return (
                  <tr key={index}>
                    <td className='expiry'>{chain[expiryIndex].expiry}</td>
                    <td className='strike'>{item.strike_price}</td>
                    <td className='bid'>
                      <span className='bidAskSize'>
                        {bidSize} x
                      </span>{' '}
                      {bid}
                    </td>
                    <td className='ask'>
                      {ask}{' '}
                      <span className='bidAskSize'>
                        x {askSize}
                      </span>
                    </td>
                    <td className='volume'>{item.v}</td>
                    <td className='oi'>{item.oi}</td>
                    <td className='iv'>{item.iv && item.iv.toFixed(2)}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </Wrapper>
  );
}
export default OptionsChain