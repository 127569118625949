const renderPostTemplate = [
  {
    name: 'last',
    displayName: 'Last',
    obj: 'postMarketHlocv',
    key: 'c',
  },
  {
    name: 'move',
    displayName: 'Move',
    obj: 'realTimeCalcs',
    key: 'postMarketMove',
  },
];

export default renderPostTemplate;