import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangePicker = ({ setStartDate, setEndDate }) => {
  const [startDate, setStart] = useState(null);
  const [endDate, setEnd] = useState(null);

  const handleStartDateChange = (date) => {
    setStart(date);
    setStartDate(date.toISOString().substr(0, 10));
  };

  const handleEndDateChange = (date) => {
    setEnd(date);
    setEndDate(date.toISOString().substr(0, 10));
  };

  return (
    <div>
      <DatePicker
        selected={startDate}
        onChange={handleStartDateChange}
        placeholderText='Start Date'
      />
      <DatePicker
        selected={endDate}
        onChange={handleEndDateChange}
        placeholderText='End Date'
      />
    </div>
  );
};

export default DateRangePicker;
