import styled from 'styled-components';
import { getMarketCloseHour, getMinuteOfSession } from '../../../utils/marketStatus/marketDates';
import { useDataContext } from '../../context/data/dataContext';

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  .candleTimeRemainingText {
    font-size: 0.6rem;
  }
`;

const getTimeRemaining = (latestTimestamp, timeframe, status) => {
  let timeRemaining;
  const interval = timeframe.multiplier;
  const time = new Date(latestTimestamp);
  if (timeframe.timespan === 'minute') {
    let minutes = interval - 1 - (getMinuteOfSession(latestTimestamp) % interval);
    let seconds = 60 - time.getUTCSeconds() === 60 ? 0 - 1 : 60 - time.getUTCSeconds() - 1;
    if (time.getUTCSeconds() === 0) {
      minutes += 1;
    }
    let hours = 0;
    if (minutes >= 60) {
      minutes -= 60;
      hours += 1;
    }
    const secondsRemaining = String(seconds + 1).padStart(2, '0');
    const minutesRemaining = String(minutes).padStart(2, '0');
    const hoursRemaining = String(hours).padStart(2, '0');
    timeRemaining = `${hoursRemaining}:${minutesRemaining}:${secondsRemaining}`;
  } else if (timeframe.timespan === 'day' || timeframe.timespan === 'week') {
    let minutes = time.getUTCMinutes();
    let seconds = 60 - time.getUTCSeconds() === 60 ? 0 - 1 : 60 - time.getUTCSeconds() - 1;
    if (time.getUTCSeconds() === 0) {
      minutes += 1;
    }
    let hours = time.getUTCHours();
    if (minutes >= 60) {
      minutes -= 60;
      hours += 1;
    }
    const secondsRemaining = String(seconds + 1).padStart(2, '0');
    const marketCloseUTCHour = getMarketCloseHour(latestTimestamp);
    const hoursRemaining = String(marketCloseUTCHour - 1 - hours).padStart(2, '0');
    const minutesRemaining = String(60 - 1 - minutes).padStart(2, '0');
    timeRemaining = `${hoursRemaining}:${minutesRemaining}:${secondsRemaining}`;
  }
  return timeRemaining;
}

const CandleTimeRemaining = ({ timeframe, extendedSession }) => {
  // console.log('CandleTimeRemaining');
  const { latestTimestamp, status } = useDataContext();
  let timeRemaining = '';
  if (status === 'open') {
    timeRemaining = getTimeRemaining(latestTimestamp, timeframe, status);
  } else if (status === 'premarket' || status === 'postmarket') {
    if (extendedSession) {
      timeRemaining = getTimeRemaining(latestTimestamp, timeframe, status);
    }
  }

  return (
    <Wrapper>
      <div className='candleTimeRemainingText'>{timeRemaining}</div>
    </Wrapper>
  );
};
export default CandleTimeRemaining;
