const updatePriceline = (
  statusNow,
  extendedSession,
  activeTimeframe,
  priceLine,
  candles,
  price,
  setpriceLine
) => {
  if (statusNow === 'postmarket') {
    if (!extendedSession || activeTimeframe !== 'minute') {
      if (priceLine) {
        candles.removePriceLine(priceLine);
      }
      // console.log(lastCandle.c);
      const line = candles.createPriceLine({
        price: price,
        color: '#2197f3',
        axisLabelVisible: true,
        title: 'Post',
      });
      setpriceLine(line);
    }
  } else if (statusNow === 'premarket') {
    if (!extendedSession || activeTimeframe !== 'minute') {
      if (priceLine) {
        candles.removePriceLine(priceLine);
      }
      const line = candles.createPriceLine({
        price: price,
        color: '#fb8c02',
        axisLabelVisible: true,
        title: 'Pre',
      });
      setpriceLine(line);
    }
  }
};

export default updatePriceline;