import styled from 'styled-components';
import TickerInput from './TickerInput';
import TickersInputted from './TickersInputted';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  .listTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

const TickerLists = ({ settings, setSettings }) => {

  return (
    <Wrapper>
      <div className='listTypeContainer'>
        <h3>Watch List</h3>
        <TickerInput
          settings={settings}
          setSettings={setSettings}
          listName={'watchlist'}
        />
        <TickersInputted
          settings={settings}
          setSettings={setSettings}
          listName={'watchlist'}
        />
      </div>
      <div className='listTypeContainer'>
        <h3>Block List</h3>
        <TickerInput
          settings={settings}
          setSettings={setSettings}
          listName={'blocklist'}
        />
        <TickersInputted
          settings={settings}
          setSettings={setSettings}
          listName={'blocklist'}
        />
      </div>
      <div className='listTypeContainer'>
        <h3>Override List</h3>
        <TickerInput
          settings={settings}
          setSettings={setSettings}
          listName={'overrideList'}
        />
        <TickersInputted
          settings={settings}
          setSettings={setSettings}
          listName={'overrideList'}
        />
      </div>
    </Wrapper>
  );
};

export default TickerLists;
