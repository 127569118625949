import { useState } from 'react';
import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  .typeContainer {
    display: flex;
    align-items: center;
  }
`;

const Type = ({ tickerDetails, updateDetails, possibleTypes }) => {
  const [includedTypes, setIncludedTypes] = useState(
    tickerDetails?.type || possibleTypes
  );

  // const checkedTypes = tickerDetails?.type || possibleTypes;

  return (
    <Wrapper>
      <h3>Type</h3>
      <div className='typeContainer'>
        {possibleTypes.map((type) => (
          <div key={type}>
            <input
              type='checkbox'
              checked={includedTypes.includes(type)}
              onChange={(e) => {
                if (e.target.checked) {
                  setIncludedTypes([...includedTypes, type]);
                  updateDetails([...includedTypes, type], 'type');
                } else {
                  if (includedTypes.length === 1) return;
                  const newTypes = includedTypes.filter(
                    (includedType) => includedType !== type
                  );
                  setIncludedTypes(newTypes);
                  updateDetails(newTypes, 'type');
                }
              }}
            />
            <label>{type}</label>
          </div>
        ))}
      </div>
    </Wrapper>
  );
};

export default Type;
