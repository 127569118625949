const widgetTemplate = {
  name: '',
  watchlist: [],
  blocklist: [],
  tickerDetails: {},
  dailyQuotes: {},
  realTimeQuotes: {},
  overrideList: [],
  eodSort: {},
  realTimeSort: {},
};

export default widgetTemplate;
