import styled from 'styled-components';
import getStartDate from '../getStartDate';
import parseTimeInterval from '../parseTimeInterval';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0.1rem;
  padding-top: 0.1rem;
  .chartTimeframe {
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
  }
  ${(props) => props.css}
`;

const MultiTickerTimeframes = ({
  slotId,
  widgetId,
  timeframe,
  calendarTimeframes,
  setTimeframe,
  extendedSession,
  displayTimeframeOptions,
  setDisplayTimeframeOptions,
  handleCalendarTimeframe,
  activeCalendarTimeframe,
  setActiveCalendarTimeframe,
  ...props
}) => {
  // console.log('Settings');

  const activeTimeframeCss = 'color: blue;';
  let css = '';
  calendarTimeframes.forEach((interval) => {
    if (interval === timeframe.calendarTimeframe) {
      css += `.${interval} {${activeTimeframeCss}}`;
    }
  });

  return (
    <Wrapper css={css}>
      {calendarTimeframes.map((interval) => {
          return (
            <div
              key={
                'slot' + slotId + 'widget' + widgetId + 'chart' + interval
              }
              className={`noselect chartTimeframe ${interval}`}
              onClick={() => {
                if (interval !== timeframe.calendarTimeframe) {
                  handleCalendarTimeframe(interval);
                  setDisplayTimeframeOptions(!displayTimeframeOptions);
                } else {
                  setDisplayTimeframeOptions(!displayTimeframeOptions);
                }
              }}
            >
              {interval}
            </div>
          );
        // }
      })}
    </Wrapper>
  );
};
export default MultiTickerTimeframes;
