import { useEffect } from 'react';
import { io } from 'socket.io-client';
import { usePublicContext } from '../context/publicContext';
import { useApiContext } from './context/api/apiContext';
import { useDataContext } from './context/data/dataContext';
import Home from './Home';

const Data = () => {
  console.log('Data rendered');
  const { token } = usePublicContext();
  const { getInitializationData } = useApiContext();
  const { setRealTimeData, latestTimestamp, session } = useDataContext();

  useEffect(() => {
    // This effect will only run once when the component mounts
    let isMounted = true; // This flag is used to prevent state updates after unmount

    const socket = io(process.env.REACT_APP_SOCKET_URL, {
      auth: { token },
    });

    socket.on('connect', () => {
      console.log('stockSocket connected');
    });

    let setInitialData = false;

    socket.on('initializationData', async (e) => {
      if (!setInitialData && isMounted) {
        const initData = await getInitializationData();
        setRealTimeData({ ...e, ...initData });
        setInitialData = true;
      }
    });

    socket.on('realTimeData', (e) => {
      if (setInitialData && isMounted) {
        setRealTimeData(e);
      }
    });

    socket.on('disconnect', (reason) => {
      console.log('Socket disconnected:', reason);
      if (reason === 'io server disconnect' && isMounted) {
        // the disconnection was initiated by the server, you need to reconnect manually
        socket.connect();
      }
    });

    // Cleanup function to run when the component unmounts
    return () => {
      isMounted = false;
      socket.close();
    };
  }, []); // Empty dependency array means this effect will only run on mount and unmount

  if (!token) {
    //could potentially add status bar here
    return <div>Loading Token</div>;
  } else if (session !== 'closed' && !latestTimestamp) {
    return <div>Loading Data</div>;
  } else {
    return <Home latestTimestamp={latestTimestamp} />;
  }
};
export default Data;
