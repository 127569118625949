const renderOpenTemplate = [
  {
    name: 'last',
    displayName: 'Last',
    obj: 'openMarketHlocv',
    key: 'c',
  },
  {
    name: 'move',
    displayName: 'Move',
    obj: 'realTimeCalcs',
    key: 'move',
  },
];

export default renderOpenTemplate;