import styled from 'styled-components';
import { useState } from 'react';
import { useDataContext } from '../../context/data/dataContext';

const Wrapper = styled.div`
  /* display: flex; */
  .ticker {
    color: ${(props) => props.tickerColor};
  }
  .tickerName {
    background-color: white;
    width: fit-content;
    font-size: 1rem;
    margin: 0rem 0.25rem;
  }
  .tickerDetails {
    background-color: white;
    width: fit-content;
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
    padding: 0.25rem 0rem;
  }
  .tickerMarketCap {
    background-color: white;
    width: fit-content;
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
    padding: 0.25rem 0rem;
  }
  .tickerDescription {
    display: ${(props) => props.descriptionDisplay};
    background-color: white;
    width: 20rem;
    max-height: 12rem;
    overflow: scroll;
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
    padding: 0.25rem 0.25rem;
  }
`;

//add in more data
const Legend = ({ ticker, freezeTicker, setFreezeTicker }) => {
  // console.log('Legend');

  const { tickerDetails: allTickerDetails } = useDataContext();
  const tickerDetails = allTickerDetails[ticker];

  const [showDescription, setShowDescription] = useState(false);
  let descriptionDisplay = 'none';
  if (showDescription) {
    descriptionDisplay = 'inline-block';
  }

  let tickerColor = '';
  if (freezeTicker) {
    tickerColor = 'blue';
  }

  return (
    <Wrapper tickerColor={tickerColor} descriptionDisplay={descriptionDisplay}>
      {tickerDetails.name && (
        <div className='tickerName noselect'>
          <span className='ticker' onClick={() => setFreezeTicker(!freezeTicker)}>
            {ticker}
          </span>{' '}
          &bull;{' '}
          <span onClick={() => setShowDescription(!showDescription)}>
            {tickerDetails.cleanedName || tickerDetails.name}
          </span>
        </div>
      )}
      {tickerDetails.sector && tickerDetails.industry ? (
        <div className='tickerDetails'>
          {tickerDetails.sector + ' '}&bull;{' ' + tickerDetails.industry}
        </div>
      ) : (
        <div className='tickerDetails'>{tickerDetails.sic}</div>
      )}
      {tickerDetails.marketCap && (
        <div className='tickerMarketCap'>
          {'Market Cap: ' + tickerDetails.marketCap}
        </div>
      )}
      {tickerDetails.description && (
        <div className='tickerDescription'>{tickerDetails.description}</div>
      )}
    </Wrapper>
  );
};
export default Legend;
