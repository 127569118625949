import React, { useContext, useReducer } from 'react';
import { SET_REAL_TIME_DATA } from './actions';
import reducer from './reducer';

const initialState = {
  latestTimestamp: null,
  session: null,
  dbTickers: [],
  tickerDetails: {},
  tickerStats: {},
  eodUpdateReceived: 0,
  lastEodHlocv: {},
  secondLastEodHlocv: {},
  preMarketHlocv: {},
  openMarketHlocv: {},
  postMarketHlocv: {},
  currentHlocv: {},
  trailingIntervals: {},
  topBottomHour: {},
};

const DataContext = React.createContext();

const DataProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const setRealTimeData = (realTimeData) => {
    dispatch({
      type: SET_REAL_TIME_DATA,
      payload: realTimeData,
    });
  };

  return (
    <DataContext.Provider
      value={{
        ...state,
        setRealTimeData,
      }}
    >
      {children}
    </DataContext.Provider>
  );
};

const useDataContext = () => {
  return useContext(DataContext);
};

export { DataProvider, initialState, useDataContext };
