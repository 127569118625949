import { timeToLocal } from '../../../utils/marketStatus/dateTimeFunctions';
import getTimeStr from '../../../utils/getTimeStr';

// visible range is
// - right offset candles
// - candles in visible range
// - one extra candle on left for padding
const setTimescale = (chart, initialtimeSeries, timeIntervals, timeframe, displayTime) => {
  // copy initialtimeSeries to avoid mutating it
  const timeSeries = [...initialtimeSeries];
  timeSeries.reverse();

  // set variables
  const timespan = timeframe.timespan;
  const multiplier = timeframe.multiplier;
  let rightOffset = 15; // default to 15 candles
  let visibleBars = 150 + rightOffset; // default to 150 bars visible

  // set visibleBars based on timeframe
  if (timespan === 'minute') {
    timeIntervals.forEach((interval) => {
      if (interval.name === `${multiplier}m`) {
        visibleBars = interval.visibleRangeReg + rightOffset;
      }
    });
  }

  // set visibleBars based on start date
  // const startTime = 1697169600000; //"2023-09-14"
  // for (let i = 0; i < timeSeries.length; i++) {
  //   // first candle is day before start date
  //   if (startTime > timeSeries[i].t) {
  //     visibleBars = i + rightOffset;
  //     // visibleBars = Math.round(i * 1.05);
  //     // rightOffset = visibleBars - i;
  //     console.log('i', i);
  //     break;
  //   }
  // }

  // set visibleBars to chart
  let lookupIndex = timeSeries[visibleBars] ? visibleBars : timeSeries.length - 1;
  const fromTime = timeSeries[lookupIndex].t;
  chart.timeScale().setVisibleRange({
    from: timeToLocal(fromTime),
    to: Date.now() / 1000,
  });

  console.log('--------------------');
  console.log('rightOffset', rightOffset);
  console.log('visibleBars', visibleBars);
  // console.log(getTimeStr(startTime));
  console.log(getTimeStr(timeSeries[10].t));
  console.log(getTimeStr(timeSeries[lookupIndex].t));

  chart.timeScale().applyOptions({
    rightOffset: rightOffset,
    timeVisible: displayTime,
    secondsVisible: false,
  });
};

export default setTimescale;
