import styled from 'styled-components';
import { useState } from 'react';
import TickerLists from './TickerLists';
import TickerDetails from './TickerDetails';
import TickerFilters from './TickerFilters';
import TickerSorts from './TickerSorts';
import WidgetName from './WidgetName';
import getSettingsDescription from './getSettingsDescription';
import { useConfigContext } from '../../context/config/configContext';
import { useUserContext } from '../../context/user/userContext';

const Wrapper = styled.div`
  section {
    display: flex;
  }
  form div h3 {
    margin: 0.5rem 1rem;
  }
  .listTypeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .buttonContainer {
    display: flex;

  }
`;

const EditWidget = ({ setPage, widgetConfig, possibleTypes }) => {
  // console.log('EditWidget', widgetConfig);
  const { isLoading } = useUserContext();
  const { addConfig, editConfig } = useConfigContext();

  const filterOptions = [
    'marketCap',
    'move',
    'preMarketMove',
    'postMarketMove',
    'min1Move',
    'min5Move',
    'min10Move',
    'min15Move',
    'min30Move',
  ];
  const sortTypes = ['eodSort', 'realTimeSort'];
  const sortOptions = [
    'marketCap',
    'move',
    'preMarketMove',
    'postMarketMove',
    'min1Move',
    'min5Move',
    'min10Move',
    'min15Move',
    'min30Move',
  ];

  const [settings, setSettings] = useState(widgetConfig);
  const [description, setDescription] = useState(
    getSettingsDescription(widgetConfig)
  );
  // console.log('settings', settings);

  const handleSettingsChange = (newSettings) => {
    const newDescription = getSettingsDescription(newSettings);
    setSettings(newSettings);
    setDescription(newDescription);
  };

  const [saveMessage, setSaveMessage] = useState('test');
  const handleSave = async () => {
    let res;
    console.log('settings', settings._id);
    if (settings._id) {
      res = await editConfig('widget', settings);
    } else {
      res = await addConfig('widget', settings);
    }
    setSaveMessage(res.msg);
    if (res.status === 1) {
      setPage('view');
    }
  };

  return (
    <Wrapper>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className='buttonContainer'>
          <button onClick={() => setPage('view')}>Cancel</button>
          <button onClick={() => handleSave()}>Save</button>
          <div>{saveMessage}</div>
        </div>
      )}
      {!isLoading && (
        <form>
          <div className='section'>
            <WidgetName
              settings={settings}
              setSettings={handleSettingsChange}
            />
          </div>
          <div className='section'>
            <h3>Description</h3>
            {description &&
              description.length > 0 &&
              description.map((line) => (
                <div key={'descriptionLine' + line}>{line}</div>
              ))}
          </div>
          <div className='section'>
            <TickerDetails
              settings={settings}
              setSettings={handleSettingsChange}
              possibleTypes={possibleTypes}
            />
          </div>
          <div className='section'>
            <TickerLists
              settings={settings}
              setSettings={handleSettingsChange}
            />
          </div>
          <div className='section'>
            <TickerFilters
              settings={settings}
              setSettings={handleSettingsChange}
              filterType='dailyQuotes'
              filterOptions={filterOptions}
            />
          </div>
          <div className='section'>
            <TickerFilters
              settings={settings}
              setSettings={handleSettingsChange}
              filterType='realTimeQuotes'
              filterOptions={filterOptions}
            />
          </div>
          {sortTypes.map((sortType) => (
            <div className='section' key={'widgetConfigSection' + sortType}>
              <TickerSorts
                settings={settings}
                setSettings={handleSettingsChange}
                sortType={sortType}
                sortTypes={sortTypes}
                sortOptions={sortOptions}
              />
            </div>
          ))}
        </form>
      )}
    </Wrapper>
  );
};

export default EditWidget;
