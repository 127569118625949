import { useState, useRef } from 'react';
import styled from 'styled-components';
import OutsideClickCloseMenu from '../../../utils/OutsideClickCloseMenu';
import { useConfigContext } from '../../context/config/configContext';

const Wrapper = styled.div`
  width: 6rem;
  position: relative;
  min-height: 2rem;
  .input-container {
    display: flex;
    flex-direction: column;
  }
  input {
    width: 100%;
    padding: 6px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  ul {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1;
    max-height: 20rem;
    overflow-y: scroll;
    top: 100%;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
  .highlighted {
    background-color: #f2f2f2;
  }
`;

const TickerInput = ({ settings, setSettings, listName }) => {
  const inputRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false);
  const { availableTickers } = useConfigContext();
  const [remainingTickers, setRemainingTickers] = useState(availableTickers)
  const [inputValue, setInputValue] = useState('');
  const [suggestions, setSuggestions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const addTicker = (ticker, listName) => {
    if (settings[listName].includes(ticker)) return;
    const newTickers = [...settings[listName], ticker];
    newTickers.sort();
    setSettings({
      ...settings,
      [listName]: newTickers,
    });
    setRemainingTickers(remainingTickers.filter((t) => t !== ticker));
  };

  const handleInputChange = (e) => {
    setShowMenu(true);
    const value = e.target.value.toUpperCase();
    setInputValue(value);
    setSuggestions(
      remainingTickers.filter((ticker) => ticker.startsWith(value))
    );
    setHighlightedIndex(-1);
  };

  const handleInputKeyDown = (e) => {
    const { key } = e;
    if (key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (key === 'ArrowUp') {
      setHighlightedIndex(
        (prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length
      );
    } else if (key === 'Enter') {
      if (highlightedIndex !== -1) {
        setInputValue(suggestions[highlightedIndex]);
        addTicker(suggestions[highlightedIndex], listName);
        setInputValue('');
        setSuggestions([]);
      } else if (suggestions.includes(inputValue)) {
        addTicker(inputValue, listName);
        setInputValue('');
        setSuggestions([]);
        //remove focus on input
        e.target.blur();
      }
    }
  };
  
  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    addTicker(suggestion, listName);
    setInputValue('');
    setSuggestions([]);
  };

  const handleSuggestionHover = (index) => {
    setHighlightedIndex(index);
  };

  return (
    <Wrapper ref={inputRef}>
      <OutsideClickCloseMenu inputRef={inputRef} setShowMenu={setShowMenu}>
        <div className='input-container'>
          <input
            type='text'
            value={inputValue}
            onClick={() => setShowMenu(true)}
            onChange={handleInputChange}
            onKeyDown={handleInputKeyDown}
            placeholder={'Enter a ticker'}
          />
          {showMenu && suggestions.length > 0 && (
            <ul>
              {suggestions.map((suggestion, index) => (
                <li
                  key={index}
                  onClick={() => handleSuggestionClick(suggestion)}
                  onMouseEnter={() => handleSuggestionHover(index)}
                  className={index === highlightedIndex ? 'highlighted' : ''}
                >
                  {suggestion}
                </li>
              ))}
            </ul>
          )}
        </div>
      </OutsideClickCloseMenu>
    </Wrapper>
  );
};

export default TickerInput;
