const layoutTemplate = {
  name: '',
  configs: {
    gridTemplateColumns: '30rem 30rem',
    gridTemplateRows: '20rem 20rem',
  },
  slots: [],
};

const layoutTemplate1 = {
  name: '',
  configs: {
    gridTemplateColumns: '30rem 30rem',
    gridTemplateRows: '17rem 20rem 20rem',
  },
  slots: [
    {
      name: 'slot name',
      id: 'S000001',
      gridColumn: 1,
      gridRow: 1,
      widgets: [
        {
          name: 'Top Gainers',
          id: 'W000001',
          visualId: 'L000001',
        },
      ],
    },
    {
      name: 'slot name',
      id: 'S000001',
      gridColumn: 2,
      gridRow: 1,
      widgets: [
        {
          name: 'Bottom Losers',
          id: 'W000002',
          visualId: 'L000001',
        },
      ],
    },
    {
      name: 'slot name',
      id: 'S000001',
      gridColumn: '1/3',
      gridRow: 2,
      widgets: [
        {
          name: 'Chart',
          id: 'W000001',
          visualId: 'C000001',
        },
      ],
    },
    {
      name: 'slot name',
      id: 'S000001',
      gridColumn: '1/3',
      gridRow: 3,
      widgets: [
        {
          name: 'Chart',
          id: 'W000002',
          visualId: 'C000001',
        },
      ],
    },
  ],
};

export default layoutTemplate;
