import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useConfigContext } from '../context/config/configContext.js';
import Chart from '../visuals/Chart.js';
import List from '../visuals/List.js';

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  border: solid 1px grey;
  //hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  ${(props) => props.css}
`;

const Widget = ({ widgetConfigs, widgetVisualConfigs, widgetTickers, ...props }) => {
  // console.log('Widget');
  // const type = widgetVisualConfigs.id[0];
  const id = widgetVisualConfigs._id || widgetVisualConfigs.id;
  const { charts, lists } = useConfigContext();
  let type;
  if (charts[id] || id[0] === 'C') {
    type = 'C';
  } else if (lists[id] || id[0] === 'L') {
    type = 'L';
  }

  //set CSS
  let css = '';
  if (type === 'L') {
    css += 'overflow-y: scroll;';
  } else if (type === 'C') {
    // console.log(props.renderData);
  }

  useEffect(() => {
    // console.log('Widget useEffect');
    console.log('widgetTickers', widgetTickers)
    console.log('props', props)
    console.log('widgetVisualConfigs', widgetVisualConfigs)
  }, []);

  return (
    <Wrapper css={css}>
      {type === 'L' && (
        <List
          slotId={props.slotId}
          widgetId={props.widgetId}
          name={widgetConfigs.name}
          configs={widgetVisualConfigs}
          tickers={widgetTickers}
          renderData={props.renderData}
        />
      )}
      {type === 'C' && (
        <Chart
          tickers={widgetTickers} //array of tickers
          slotId={props.slotId} //slotId integer
          widgetId={props.widgetId} //widgetId integer
          currentHlocv={props.currentHlocv} //currentHlocv object
          extendedSession={widgetVisualConfigs.extendedSession} //extendedSession boolean
          showVolume={widgetVisualConfigs.showVolume} //showVolume boolean
          handleScale={widgetVisualConfigs.timeScroll} //handleScale/timeScroll boolean
          handleScroll={widgetVisualConfigs.priceScroll} //handleScroll/priceScroll boolean
          defaultTimeInterval={widgetVisualConfigs.defaultTimeInterval} //defaultTimeInterval string ex: '1m'
          timeIntervals={widgetVisualConfigs.timeIntervals} //array of timeInterval obj (name string & visible range integer)
          defaultCalendarTimeframe={
            widgetVisualConfigs.defaultCalendarTimeframe
          } //timeframe string ex: 'D'
          calendarTimeframes={widgetVisualConfigs.calendarTimeframes} //array of timeframes ex: ['D', 'W', 'M', 'Q', 'Y']
          renderData={props.renderData} //renderData object
          latestTimestamp={props.latestTimestamp} //latestTimestamp integer
        />
      )}
    </Wrapper>
  );
};
export default Widget;
