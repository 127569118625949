import { useState, useRef } from 'react';
import styled from 'styled-components';
import { useConfigContext } from '../../context/config/configContext';

const Wrapper = styled.div`
  position: absolute;
  background-color: white;
  width: 100%;
  top: 0px;
  left: 0;
  z-index: 999;
  font-size: 0.75rem;
  .inputsContainer {
    display: flex;
    flex-direction: row;
  }
  .inputsColumn {
    display: flex;
    flex-direction: column;
    margin: 0rem 0.5rem;
  }
  .inputRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .inputRow input {
    width: 2rem;
    height: 0.7rem;
    margin-left: 0.25rem;
  }
  .addButton,
  .deleteButton,
  .saveButton,
  .cancelButton {
    width: 6rem;
    font-size: 0.6rem;
  }
  .inputRow .newSlotName {
    width: 6rem;
  }

  .inputsColumn .layoutName {
    width: 8rem;
    font-size: 0.7rem;
  }
`;

const GridWrapper = styled.div`
  display: grid;
  .tile {
    height: 100%;
    width: 100%;
    border: 1px solid lightblue;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .tileContent {
    display: flex;
    flex-direction: column;
  }
  ${(props) => props.layoutGridCss}
`;

const EditGrid = ({
  settings,
  setSettings,
  setPage,
  handleSave,
  saveMessage,
  setSaveMessage,
}) => {
  //Enhancements:
  //Add arrow key to grow/shrink slot
  // Need to move logit out of inputs to functions for logic checks
  //break out EditSlots into separate component. Possibly widgets(visuals+scans) as well.
  //IDs are required to make sellable.
  //***********

  const visualTypes = ['chart', 'list'];
  const [layoutName, setLayoutName] = useState(settings.name);

  //create grid
  //extract gridTemplateColumns and gridTemplateRows
  const gridConfigs = settings.configs;
  const gridTemplateColumns = gridConfigs.gridTemplateColumns;
  const gridTemplateRows = gridConfigs.gridTemplateRows;
  const parseGridTemplate = (gridTemplateStr) => {
    const gridTemplateArr = gridTemplateStr.split('rem ');
    gridTemplateArr[gridTemplateArr.length - 1] = gridTemplateArr[
      gridTemplateArr.length - 1
    ].slice(0, -2);
    return gridTemplateArr.map((item) => parseInt(item));
  };
  //set gridTemplateColumns and gridTemplateRows to state as array of numbers
  const [columnWidths, setColumnWidths] = useState(
    parseGridTemplate(gridTemplateColumns)
  );
  const [rowHeights, setRowHeights] = useState(
    parseGridTemplate(gridTemplateRows)
  );

  //create grid based on number of columnWidths and rowHeights
  const [columns, setColumns] = useState(columnWidths.length);
  const [rows, setRows] = useState(rowHeights.length);
  const grid = (() => {
    const grid = [];
    for (let r = 0; r < rows; r++) {
      const row = [];
      for (let c = 0; c < columns; c++) {
        row.push(1);
      }
      grid.push(row);
    }
    return grid;
  })();

  //create css for grid of tiles - template columns/rows and each tile
  const columnCss = columnWidths.join('rem ') + 'rem';
  const rowCss = rowHeights.join('rem ') + 'rem';
  let layoutGridCss = `grid-template-columns: ${columnCss};`;
  layoutGridCss += ` grid-template-rows: ${rowCss};`;
  const tiles = []; //[column, row], later push slotIndex, which means occupied
  grid.forEach((column, r) => {
    column.forEach((tile, c) => {
      const columns = column.length;
      tiles.push([c, r]);
      layoutGridCss += ` .tile${r * columns + c} {
        grid-column: ${c + 1};
        grid-row: ${r + 1};
      }`;
    });
  });

  //create slots
  // const colors = ['red', 'blue', 'green', 'yellow', 'orange', 'purple', 'pink'];
  //array of light shaded colors
  const colors = [
    'rgba(255, 0, 0, 0.2)',
    'rgba(0, 0, 255, 0.2)',
    'rgba(0, 255, 0, 0.2)',
    'rgba(255, 255, 0, 0.2)',
    'rgba(255, 165, 0, 0.2)',
    'rgba(128, 0, 128, 0.2)',
    'rgba(255, 192, 203, 0.2)',
  ];
  const [slotConfigs, setSlotConfigs] = useState(settings.slots);
  //get gridColumn and gridRow start and end tile for given slot
  const getSubGrid = (i) => {
    if (slotConfigs[i] === undefined) {
      return { columnStart: 0, columnEnd: 0, rowStart: 0, rowEnd: 0 };
    }
    const gridColumns = slotConfigs[i].gridColumn;
    let columnStart, columnEnd;
    if (typeof gridColumns === 'string' && gridColumns.includes('/')) {
      const [start, end] = gridColumns.split('/');
      columnStart = parseInt(start);
      columnEnd = parseInt(end) - 1; //subtract 1 to get correct number of tiles
    } else {
      columnStart = parseInt(gridColumns);
      columnEnd = columnStart;
    }
    const gridRows = slotConfigs[i].gridRow;
    let rowStart, rowEnd;
    if (typeof gridRows === 'string' && gridRows.includes('/')) {
      const [start, end] = gridRows.split('/');
      rowStart = parseInt(start);
      rowEnd = parseInt(end) - 1; //subtract 1 to get correct number of tiles
    } else {
      rowStart = parseInt(gridRows);
      rowEnd = rowStart;
    }
    return { columnStart, columnEnd, rowStart, rowEnd };
  };
  //create css for slot color overlay
  slotConfigs.forEach((slotConfig, i) => {
    //parse gridColumn and gridRow to start and end tiles
    // console.log('slotConfig', slotConfig);
    const subGrid = getSubGrid(i);
    const rowStart = subGrid.rowStart;
    const rowEnd = subGrid.rowEnd;
    const columnStart = subGrid.columnStart;
    const columnEnd = subGrid.columnEnd;
    //apply background-color to each tile
    for (let r = rowStart - 1; r < rowEnd; r++) {
      for (let c = columnStart - 1; c < columnEnd; c++) {
        //map slot to tile
        tiles.forEach((tile) => {
          if (tile[0] === c && tile[1] === r) {
            tile.push(i);
          }
        });
        layoutGridCss += ` .tile${r * columns + c} {
          background-color: ${colors[i]};
        }`;
      }
    }
  });

  //allow user to select tile in grid to edit
  const tileRef = useRef(null);
  const [selectedColumn, setSelectedColumn] = useState(0); //think about right defaults
  const [selectedRow, setSelectedRow] = useState(0);
  const [selectedTile, setSelectedTile] = useState(0);
  layoutGridCss += ` .tile${selectedTile} {
    box-sizing: border-box;
    border: 2px solid red;
    padding: 10px;
  }`;

  //allow user to select slot to edit
  const [selectedSlot, setSelectedSlot] = useState(tiles[selectedTile][2]);

  //grow/shrink slot
  const subGrid = getSubGrid(selectedSlot);
  const slotColumnStart = subGrid.columnStart;
  const slotColumnEnd = subGrid.columnEnd;
  const slotRowStart = subGrid.rowStart;
  const slotRowEnd = subGrid.rowEnd;
  const areOccupied = (tileIndexes) => {
    const occupied = [];
    tileIndexes.forEach((tile) => {
      if (tiles[tile].length > 2) occupied.push(tile);
    });
    if (occupied.length === 0) return false;
    else return occupied;
  };

  //create new slot
  const [newSlotName, setNewSlotName] = useState('');

  //edit slot
  const { charts, lists, widgets } = useConfigContext();

  //validate fields before saving
  const validateFields = () => {
    if (layoutName === '') {
      const msg = 'Please enter a name for the layout.';
      return { status: 0, msg };
    }
    if (slotConfigs.length === 0) {
      const msg = 'Please add at least one slot to the layout.';
      return { status: 0, msg };
    }
    //check for visual type and id
    slotConfigs.forEach((slotConfig, i) => {
      if (slotConfig.visualType === '') {
        const msg = `Please select a visual type for slot ${i + 1}.`;
        return { status: 0, msg };
      }
      if (slotConfig.visualId === '') {
        const msg = `Please select a visual for slot ${i + 1}.`;
        return { status: 0, msg };
      }
      if (
        slotConfig.visualType === 'chart' ||
        slotConfig.visualType === 'list'
      ) {
        if (slotConfig.id === '') {
          //check for widget/scan id
          const msg = `Please select a widget/scan for slot ${i + 1}.`;
          return { status: 0, msg };
        }
      }
    });
    return { status: 1, msg: 'Success' };
  };

  //save layout
  const setSettingsAndSave = () => {
    const layout = {
      //if settings._id exists, include it, this will edit instead of add config
      ...(settings._id && { _id: settings._id }),
      name: layoutName,
      configs: {
        gridTemplateColumns: columnCss,
        gridTemplateRows: rowCss,
      },
      slots: slotConfigs,
    };
    handleSave(layout);
    //based on response, setSettings..
    console.log('saveMessage', saveMessage);
    setSettings(layout);
  };

  return (
    <Wrapper>
      <div className='inputsContainer'>
        <div className='inputsColumn'>
          <div className='inputRow'>
            <button
              className='saveButton'
              onClick={() => {
                const validation = validateFields();
                if (validation.status === 1) {
                  setSettingsAndSave();
                } else {
                  setSaveMessage(validation.msg);
                }
              }}
            >
              Save
            </button>
          </div>
          <div className='inputRow'>
            <button className='cancelButton' onClick={() => setPage('view')}>
              Cancel
            </button>
          </div>
        </div>
        <div className='inputsColumn'>
          <input
            type='text'
            className='layoutName'
            placeholder='Layout Name'
            id='name'
            value={layoutName}
            onChange={(e) => {
              setLayoutName(e.target.value);
            }}
          />
          <div>{saveMessage}</div>
        </div>
        <div className='inputsColumn'>
          {/* add input for number of columns */}
          <div className='inputRow'>
            <label htmlFor='columns'>Columns:</label>
            <input
              type='number'
              id='columns'
              className='gridInput'
              value={columns}
              onChange={(e) => {
                const newCount = parseInt(e.target.value);
                if (newCount < 1) return;
                setColumns(newCount);
                const newWidths = [];
                for (let i = 0; i < newCount; i++) {
                  const amount = columnWidths[i] || 10;
                  newWidths.push(amount);
                }
                setColumnWidths(newWidths);
              }}
            />
          </div>
          {/* add input for number of rows */}
          <div className='inputRow'>
            <label htmlFor='rows'>Rows:</label>
            <input
              type='number'
              id='rows'
              className='gridInput'
              value={rows}
              onChange={(e) => {
                const newCount = parseInt(e.target.value);
                if (newCount < 1) return;
                setRows(newCount);
                const newHeights = [];
                for (let i = 0; i < newCount; i++) {
                  const amount = rowHeights[i] || 10;
                  newHeights.push(amount);
                }
                setRowHeights(newHeights);
              }}
            />
          </div>
        </div>
        <div className='inputsColumn'>
          {/* add input for column size */}
          <div className='inputRow'>
            <label htmlFor='columns'>Column {selectedColumn + 1} Width:</label>
            <input
              type='number'
              id='columnWidth'
              className='gridInput'
              value={columnWidths[selectedColumn]}
              onChange={(e) => {
                if (parseInt(e.target.value) < 1) return;
                const newWidths = [...columnWidths];
                newWidths[selectedColumn] = parseInt(e.target.value);
                setColumnWidths(newWidths);
              }}
            />
          </div>
          {/* add input for row size */}
          <div className='inputRow'>
            <label htmlFor='rows'>Row {selectedRow + 1} Height:</label>
            <input
              type='number'
              id='rowHeight'
              className='gridInput'
              value={rowHeights[selectedRow]}
              onChange={(e) => {
                if (parseInt(e.target.value) < 1) return;
                const newHeights = [...rowHeights];
                newHeights[selectedRow] = parseInt(e.target.value);
                setRowHeights(newHeights);
              }}
            />
          </div>
        </div>
        <div className='inputsColumn'>
          {/* add input for selected slot gridColumn start */}
          <div className='inputRow'>
            <label htmlFor='slotColumnStart'>
              Slot {selectedSlot} Column Start:
            </label>
            <input
              type='number'
              id='slotColumnStart'
              className='gridInput'
              value={slotColumnStart}
              onChange={(e) => {
                const newSlotColumnStart = parseInt(e.target.value);
                if (newSlotColumnStart < 1) return;
                if (newSlotColumnStart > columns) return;
                if (newSlotColumnStart > slotColumnEnd) return;
                //see if tile is occupied
                if (newSlotColumnStart < slotColumnStart) {
                  //get array of tiles that would be occupied
                  const newTiles = [];
                  for (let r = slotRowStart - 1; r < slotRowEnd; r++) {
                    for (
                      let c = newSlotColumnStart - 1;
                      c < slotColumnStart - 1;
                      c++
                    ) {
                      newTiles.push(r * columns + c);
                    }
                  }
                  if (areOccupied(newTiles)) return;
                }
                const newSlotConfigs = [...slotConfigs];
                const newSlotConfig = { ...newSlotConfigs[selectedSlot] };
                if (newSlotColumnStart === slotColumnEnd) {
                  newSlotConfig.gridColumn = newSlotColumnStart;
                } else {
                  newSlotConfig.gridColumn = `${newSlotColumnStart}/${
                    slotColumnEnd + 1
                  }`;
                }
                newSlotConfigs[selectedSlot] = newSlotConfig;
                setSlotConfigs(newSlotConfigs);
              }}
            />
          </div>
          {/* add input for selected slot gridColumn end */}
          <div className='inputRow'>
            <label htmlFor='slotColumnEnd'>
              Slot {selectedSlot} Column End:
            </label>
            <input
              type='number'
              id='slotColumnEnd'
              className='gridInput'
              value={slotColumnEnd}
              onChange={(e) => {
                const newSlotColumnEnd = parseInt(e.target.value);
                if (newSlotColumnEnd < 1) return;
                if (newSlotColumnEnd > columns) return;
                if (newSlotColumnEnd < slotColumnStart) return;
                if (newSlotColumnEnd > slotColumnEnd) {
                  //get array of tiles that would be occupied
                  const newTiles = [];
                  for (let r = slotRowStart - 1; r < slotRowEnd; r++) {
                    for (let c = slotColumnEnd; c < newSlotColumnEnd; c++) {
                      newTiles.push(r * columns + c);
                    }
                  }
                  if (areOccupied(newTiles)) return;
                }
                const newSlotConfigs = [...slotConfigs];
                const newSlotConfig = { ...newSlotConfigs[selectedSlot] };
                if (newSlotColumnEnd === slotColumnStart) {
                  newSlotConfig.gridColumn = newSlotColumnEnd;
                } else {
                  newSlotConfig.gridColumn = `${slotColumnStart}/${
                    newSlotColumnEnd + 1
                  }`;
                }
                newSlotConfigs[selectedSlot] = newSlotConfig;
                setSlotConfigs(newSlotConfigs);
              }}
            />
          </div>
        </div>
        <div className='inputsColumn'>
          {/* add input for selected slot gridRow start */}
          <div className='inputRow'>
            <label htmlFor='slotRowStart'>Slot {selectedSlot} Row Start:</label>
            <input
              type='number'
              id='slotRowStart'
              className='gridInput'
              value={slotRowStart}
              onChange={(e) => {
                const newSlotRowStart = parseInt(e.target.value);
                if (newSlotRowStart < 1) return;
                if (newSlotRowStart > rows) return;
                if (newSlotRowStart > slotRowEnd) return;
                if (newSlotRowStart < slotRowStart) {
                  //get array of tiles that would be occupied
                  const newTiles = [];
                  for (let r = newSlotRowStart - 1; r < slotRowStart - 1; r++) {
                    for (let c = slotColumnStart - 1; c < slotColumnEnd; c++) {
                      newTiles.push(r * columns + c);
                    }
                  }
                  if (areOccupied(newTiles)) return;
                }
                const newSlotConfigs = [...slotConfigs];
                const newSlotConfig = { ...newSlotConfigs[selectedSlot] };
                if (newSlotRowStart === slotRowEnd) {
                  newSlotConfig.gridRow = newSlotRowStart;
                } else {
                  newSlotConfig.gridRow = `${newSlotRowStart}/${
                    slotRowEnd + 1
                  }`;
                }
                newSlotConfigs[selectedSlot] = newSlotConfig;
                setSlotConfigs(newSlotConfigs);
              }}
            />
          </div>
          {/* add input for selected slot gridRow end */}
          <div className='inputRow'>
            <label htmlFor='slotRowEnd'>Slot {selectedSlot} Row End:</label>
            <input
              type='number'
              id='slotRowEnd'
              className='gridInput'
              value={slotRowEnd}
              onChange={(e) => {
                const newSlotRowEnd = parseInt(e.target.value);
                if (newSlotRowEnd < 1) return;
                if (newSlotRowEnd > rows) return;
                if (newSlotRowEnd < slotRowStart) return;
                if (newSlotRowEnd > slotRowEnd) {
                  //get array of tiles that would be occupied
                  const newTiles = [];
                  for (let r = slotRowEnd; r < newSlotRowEnd; r++) {
                    for (let c = slotColumnStart - 1; c < slotColumnEnd; c++) {
                      newTiles.push(r * columns + c);
                    }
                  }
                  if (areOccupied(newTiles)) return;
                }
                const newSlotConfigs = [...slotConfigs];
                const newSlotConfig = { ...newSlotConfigs[selectedSlot] };
                if (newSlotRowEnd === slotRowStart) {
                  newSlotConfig.gridRow = newSlotRowEnd;
                } else {
                  newSlotConfig.gridRow = `${slotRowStart}/${
                    newSlotRowEnd + 1
                  }`;
                }
                newSlotConfigs[selectedSlot] = newSlotConfig;
                setSlotConfigs(newSlotConfigs);
              }}
            />
          </div>
        </div>
        <div className='inputsColumn'>
          {/* delete selected slot */}
          <button
            className='deleteButton'
            onClick={() => {
              // if (slotConfigs.length === 1) return;
              const newSlotConfigs = [...slotConfigs];
              newSlotConfigs.splice(selectedSlot, 1);
              setSlotConfigs(newSlotConfigs);
              setSelectedSlot(0);
            }}
          >
            Delete Slot {selectedSlot}
          </button>
          {/* add slot */}
          <div className='inputRow'>
            <button
              className='addButton'
              onClick={() => {
                if (newSlotName === '') return;
                if (selectedTile === null) return;
                if (tiles[selectedTile][2] !== undefined) return;
                const newSlotConfigs = [...slotConfigs];
                newSlotConfigs.push({
                  name: newSlotName,
                  gridColumn: tiles[selectedTile][0] + 1,
                  gridRow: tiles[selectedTile][1] + 1,
                  widgets: [
                    {
                      name: 'widget name',
                      visualType: '',
                      visualId: '',
                      id: '',
                    },
                  ],
                }); //push slot template
                setSlotConfigs(newSlotConfigs);
                setSelectedSlot(newSlotConfigs.length - 1);
                setNewSlotName('');
              }}
            >
              Add Slot
            </button>
            <input
              type='text'
              placeholder='Slot Name'
              id='newSlotName'
              className='gridInput newSlotName'
              value={newSlotName}
              onChange={(e) => {
                setNewSlotName(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
      <GridWrapper layoutGridCss={layoutGridCss}>
        {tiles.map((tile, i) => {
          return (
            <div
              key={`tile-${i}`}
              className={`tile tile${i}`}
              ref={tileRef}
              onClick={() => {
                console.log('clicked', tile);
                setSelectedColumn(tile[0]);
                setSelectedRow(tile[1]);
                setSelectedTile(i);
                if (tile[2] !== undefined) setSelectedSlot(tile[2]);
              }}
            >
              {slotConfigs &&
                slotConfigs.length > 0 &&
                tile[2] !== undefined && (
                  <div className='tileContent'>
                    {/* <div>
                      tile {i}, column {tile[0] + 1}, row {tile[1] + 1}{' '}
                    </div> */}
                    <h3>
                      Slot {tile[2]}: {slotConfigs[tile[2]].name}{' '}
                    </h3>
                    {/* dropdown of visuals names */}
                    <div>
                      <label htmlFor='visualType'>Visual Type:</label>
                      <select
                        value={slotConfigs[tile[2]].widgets[0].visualType}
                        onChange={(e) => {
                          const newSlotConfigs = [...slotConfigs];
                          const newSlotConfig = { ...newSlotConfigs[tile[2]] };
                          newSlotConfig.widgets[0].visualType = e.target.value;
                          newSlotConfigs[tile[2]] = newSlotConfig;
                          setSlotConfigs(newSlotConfigs);
                        }}
                      >
                        <option value=''>None</option>
                        {visualTypes.map((visualType) => {
                          return (
                            <option
                              key={`tile${i}` + visualType}
                              value={visualType}
                            >
                              {visualType}
                            </option>
                          );
                        })}
                      </select>
                    </div>
                    {slotConfigs[tile[2]].widgets[0].visualType !== '' && (
                      <div>
                        <label htmlFor='visualId'>Visual:</label>
                        <select
                          value={slotConfigs[tile[2]].widgets[0].visualId}
                          onChange={(e) => {
                            const newSlotConfigs = [...slotConfigs];
                            const newSlotConfig = {
                              ...newSlotConfigs[tile[2]],
                            };
                            newSlotConfig.widgets[0].visualId = e.target.value;
                            newSlotConfigs[tile[2]] = newSlotConfig;
                            setSlotConfigs(newSlotConfigs);
                          }}
                        >
                          <option value=''>None</option>
                          {slotConfigs[tile[2]].widgets[0].visualType ===
                            'chart' &&
                            Object.keys(charts).map((visualId) => {
                              return (
                                <option key={visualId} value={visualId}>
                                  {charts[visualId].name}
                                </option>
                              );
                            })}
                          {slotConfigs[tile[2]].widgets[0].visualType ===
                            'list' &&
                            Object.keys(lists).map((visualId) => {
                              return (
                                <option key={visualId} value={visualId}>
                                  {lists[visualId].name}
                                </option>
                              );
                            })}
                        </select>
                      </div>
                    )}
                    {slotConfigs[tile[2]].widgets[0].visualId !== '' && (
                      <div>
                        <label htmlFor='id'>Widget/Scan:</label>
                        <select
                          value={slotConfigs[tile[2]].widgets[0].id}
                          onChange={(e) => {
                            const newSlotConfigs = [...slotConfigs];
                            const newSlotConfig = {
                              ...newSlotConfigs[tile[2]],
                            };
                            newSlotConfig.widgets[0].id = e.target.value;
                            newSlotConfigs[tile[2]] = newSlotConfig;
                            setSlotConfigs(newSlotConfigs);
                          }}
                        >
                          <option value=''>None</option>
                          {Object.keys(widgets).map((widgetId) => {
                            return (
                              <option key={widgetId} value={widgetId}>
                                {widgets[widgetId].name}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    )}
                  </div>
                )}
            </div>
          );
        })}
      </GridWrapper>
    </Wrapper>
  );
};
export default EditGrid;
