import styled from 'styled-components';
import FiltersInput from './FilterInput';

const Wrapper = styled.div`
  
`;

const TickerFilters = ({
  settings,
  setSettings,
  filterType,
  filterOptions,
}) => {

  const updateFilter = (newFilter, filterName) => {
    let filters = settings[filterType];
    if (!filters) filters = {};
    filters[filterName] = newFilter;
    setSettings({
      ...settings,
      [filterType]: filters,
    });
  };

  return (
    <Wrapper>
      <h3>{filterType}</h3>
      {filterOptions.map((filterName) => (
        <div key={filterType + filterName}>
          <FiltersInput
            settings={settings}
            filterType={filterType}
            filterName={filterName}
            updateFilter={updateFilter}
          />
        </div>
      ))}
    </Wrapper>
  );
};

export default TickerFilters;
