const displayTemplate = {
  name: '',
  schedule: [
    {
      day: 'Sunday',
    },
    {
      day: 'Monday',
      layouts: [
        {
          startTime: '00:01:00',
          id: 'L000002',
        },
        {
          startTime: '00:00:00',
          id: 'L000002',
        },
      ],
    },
  ],
};

export default displayTemplate;
