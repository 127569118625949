// import { Navigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { usePublicContext } from '../context/publicContext';
import { useUserContext } from '../app/context/user/userContext';
import { useConfigContext } from '../app/context/config/configContext';

const authorizedUsers = [
  'steven.talafous@gmail.com',
  'chris.fiume@gmail.com',
  'joseph.talafous@gmail.com',
  'scott.key.turner@gmail.com',
];

//need to properly hold loading until configs load
const ProtectedRoute = ({ children }) => {
  // console.log('ProtectedRoute');
  const {
    isLoading: isLoadingAuth0,
    isAuthenticated,
    user,
    getAccessTokenSilently,
    logout
  } = useAuth0();
  const { token, setToken } = usePublicContext();
  const { id, email, setupUser } = useUserContext();
  const { loadConfigs } = useConfigContext();

  useEffect(() => {
    const getUserToken = async () => {
      let accessToken;
      try {
        accessToken = await getAccessTokenSilently({
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
          scope: process.env.REACT_APP_AUTH0_SCOPE,
        });
      } catch (e) {
        console.log(e.message);
      }
      setToken(accessToken);
    };
    getUserToken();
    // eslint-disable-next-line
  }, [getAccessTokenSilently, isLoadingAuth0]);

  useEffect(() => {
    if (user && token) {
      // console.log('user and token', user, token);
      const slimAuth0User = {
        auth0Email: user.email,
        auth0GivenName: user.given_name,
        auth0FamilyName: user.family_name,
      };
      (async () => {
        await setupUser(slimAuth0User);
      })();
    }
    // eslint-disable-next-line
  }, [user?.email, token]);

  // useEffect(() => {
  //   if (id) {
  //     loadConfigs('all');
  //   }
  //   console.log('id', id);
  // }, [id]);

  if (isLoadingAuth0) {
    return <div>Authenticating...</div>;
  }
  if (!isAuthenticated) {
    const domain = process.env.REACT_APP_URL;
    const redirect = `${domain}/login`;
    console.log('ProtectedRoute not authenticated, redirecting to login', redirect);
    
    return <div onClick={() => {
      logout({
        logoutParams: {
          returnTo: redirect,
        },
      });
    }}>You are not logged in. Please log in.</div>;
  }
  if (!email || !token) {
    return <div>Loading...</div>;
  }

  if (!authorizedUsers.includes(email)) {
    const domain = process.env.REACT_APP_URL || 'http://localhost:3000';
    const redirect = `${domain}/login`;
    return <div onClick={() => {
      logout({
        logoutParams: {
          returnTo: redirect,
        },
      });
    }}>You are not an authorized user. Sorry. Click here to logout.</div>;
  }

  return children;
};

export default ProtectedRoute;
