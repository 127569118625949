import styled from 'styled-components';

const Wrapper = styled.div`
  display: flex;
  margin: 0.1rem;
  border: solid 1px black;
  background-color: white;
  .sessionButton {
    font-size: 0.8rem;
    margin: 0rem 0.25rem;
  }
`;

const Session = ({ setLoading, extendedSession, setExtendedSession }) => {
  // console.log('Session');
  let session;
  if (extendedSession) {
    session = 'Ext';
  } else {
    session = 'Reg'
  }

  return (
    <Wrapper>
      <div className='noselect sessionButton' onClick={() => {
        setLoading(true);
        setExtendedSession(!extendedSession);
      }}>
        {session}
      </div>
    </Wrapper>
  );
};
export default Session;
