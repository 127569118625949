import styled from 'styled-components';
import { useState } from 'react';
import widgetTemplate from './widgets/widgetTemplate';
import { useConfigContext } from '../context/config/configContext';
import { useUserContext } from '../context/user/userContext';
import { MdContentCopy, MdModeEdit, MdOutlineDelete } from 'react-icons/md';
import EditWidget from './widgets/EditWidget';
import EditChart from './charts/EditChartContainer';
import chartTemplate from './charts/chartTemplate';
import listTemplate from './lists/listTemplate';
import EditList from './lists/EditList';
import layoutTemplate from './layouts/layoutTemplate';
import EditLayout from './layouts/EditLayout';
import displayTemplate from './displays/displayTemplate';
import EditDisplay from './displays/EditDisplay';

const Wrapper = styled.div`
  .itemContainer {
    display: flex;
    flex-direction: row;
  }
  .itemName {
    margin: 0rem 1rem;
  }
  .itemOwner {
    margin: 0rem 1rem;
  }
  .item:hover {
    /* cursor: pointer; */
  }
  .itemAction {
    display: none;
    margin: 0rem 1rem;
  }
  .itemContainer:hover .itemAction {
    display: block;
  }
`;


const ViewConfigs = ({page, setPage, ...props}) => {
  //api
  //change createApi back to production

  //Next steps:
  //Build legit config pages for testing
  //Add select display menu on hub to setActiveDisplay
  //Swap out directories in Display.js
  //Test display on hub
  //***********
  //Rename widget to scan
  //Add Widget as set of Visual + Scan
  //Add Slot as Widgets + Rotation logic
  //Built in rotation logic function throughout
  //Build in sharing functions (including trickling of dependencies)
  //Asthetics clean up
  //Fix renderData in visuals
  //Add features to scan and visuals
  //Build less flexible, purpose built widgets (SPY, etc)
  //

  //roadmap
  //could eventual branch configs like git
  // subscriber notified of upgrades but no forced upgrade
  // log trace branches in db
  //browse most popular configs
  // just an addConfig with a new name

  const possibleTypes = ['CS', 'ETF', 'ADRC'];

  const newDisplayTemplate = displayTemplate;
  const newLayoutTemplate = layoutTemplate;
  const newListTemplate = listTemplate;
  const newChartTemplate = chartTemplate;
  const newWidgetTemplate = {
    ...widgetTemplate,
    tickerDetails: {
      type: possibleTypes,
    },
  };

  const { id } = useUserContext();
  const {
    displays,
    layouts,
    charts,
    lists,
    widgets,
    addConfig,
    deleteConfig,
  } = useConfigContext();

  const configureType = props.configureType;
  const typeVars = (() => {
    let configs;
    let newConfigTemplate;
    if (configureType === 'display') {
      configs = displays;
      newConfigTemplate = newDisplayTemplate;
    } else if (configureType === 'layout') {
      configs = layouts;
      newConfigTemplate = newLayoutTemplate;
    } else if (configureType === 'chart') {
      configs = charts;
      newConfigTemplate = newChartTemplate;
    } else if (configureType === 'list') {
      configs = lists;
      newConfigTemplate = newListTemplate;
    } else if (configureType === 'widget') {
      configs = widgets;
      newConfigTemplate = newWidgetTemplate;
    }
    return {configs, newConfigTemplate};
  })();;
  const configs = typeVars.configs;
  const newConfigTemplate = typeVars.newConfigTemplate;
  
  const [selectedConfig, setSelectedConfig] = useState(newConfigTemplate);

  const handleCopy = async (configId) => {
    const res = await addConfig(configureType, configs[configId], true); //copy
    //add msg
    if (res.status === 1) {
      // setPage('view');
    }
  };

  const handleDelete = async (configId) => {
    const res = await deleteConfig(configureType, configs[configId]);
    // //add msg
    if (res.status === 1) {
      //   // setPage('view');
    }
  };

  const isInLayout = (configId) => {
    let inLayout = false;
    Object.keys(layouts).forEach((layoutId) => {
      layouts[layoutId].slots.forEach((slot) => {
        if (configureType === 'widget') {
          if (slot.widgets[0].id === configId) {
            inLayout = true;
          }
        } else if (configureType === 'chart' || configureType === 'list') {
          if (slot.widgets[0].visualId === configId) {
            inLayout = true;
          }
        }
      });
    });
    return inLayout;
  };

  return (
    <Wrapper>
      {/* add new config button */}
      {page === 'view' && (
        <div>
          <button
            onClick={() => {
              setSelectedConfig(newConfigTemplate);
              setPage('configure');
            }}
          >
            Create New
          </button>
        </div>
      )}
      {/* list out user's configs */}
      {page === 'view' && configs && Object.keys(configs).length > 0 && (
        <div>
          {Object.keys(configs).map((configId) => (
            <div key={'configList' + configId} className='itemContainer'>
              <div className='itemName'>{configs[configId].name}</div>
              {configs[configId].owner?.id &&
                configs[configId].owner.id === id && (
                  <div className='itemOwner'>me</div>
                )}
              {configs[configId].owner?.id &&
                configs[configId].owner.id !== id && (
                  <div className='itemOwner'>
                    {configs[configId].owner.firstName +
                      ' ' +
                      configs[configId].owner.lastName}
                  </div>
                )}
              <div
                className='itemAction'
                onClick={() => {
                  setSelectedConfig(configs[configId]);
                  setPage('configure');
                }}
              >
                <MdModeEdit />
              </div>
              <div
                className='itemAction'
                onClick={() => {
                  handleCopy(configId);
                }}
              >
                <MdContentCopy />
              </div>
              {!isInLayout(configId) && <div
                className='itemAction'
                onClick={() => {
                  //check if config is used in any layouts
                  handleDelete(configId);
                }}
              >
                <MdOutlineDelete />
              </div>}
            </div>
          ))}
        </div>
      )}
      {(page === 'view' && !configs) ||
        (page === 'view' && Object.keys(configs).length === 0 && (
          <div>No {configureType + 's'} saved</div>
        ))}
      {/* edit config pages */}
      {page === 'configure' && configureType === 'display' && (
        <EditDisplay
          setPage={setPage}
          displayConfig={selectedConfig}
        />
      )}
      {page === 'configure' && configureType === 'layout' && (
        <EditLayout
          setPage={setPage}
          layoutConfig={selectedConfig}
        />
      )}
      {page === 'configure' && configureType === 'chart' && (
        <EditChart
          latestTimestamp={new Date().getTime()}
          currentHlocv={{}}
          setPage={setPage}
          widgetConfig={selectedConfig}
        />
      )}
      {page === 'configure' && configureType === 'list' && (
        <EditList
          setPage={setPage}
          listConfig={selectedConfig}
        />
      )}
      {page === 'configure' && configureType === 'widget' && (
        <EditWidget
          setPage={setPage}
          widgetConfig={selectedConfig}
          possibleTypes={possibleTypes}
        />
      )}
    </Wrapper>
  );
};

export default ViewConfigs;
