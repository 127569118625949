import { useState, useRef } from 'react';
import styled from 'styled-components';
import OutsideClickCloseMenu from '../../utils/OutsideClickCloseMenu';
import { useConfigContext } from '../context/config/configContext';
import { useDataContext } from '../context/data/dataContext';

const Wrapper = styled.div`
  position: relative;
  width: 10rem;
  input {
    width: 100%;
    padding: 6px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  ul {
    position: absolute;
    width: 100%;
    margin: 0;
    padding: 0;
    list-style-type: none;
    background-color: #fff;
    border: 1px solid #ccc;
    border-top: none;
    z-index: 1;
    max-height: 25rem;
    overflow-y: scroll;
  }
  li {
    padding: 10px;
    cursor: pointer;
  }
  .highlighted {
    background-color: #f2f2f2;
  }
`;

const TickerInput = () => {
  const { appPage, setAppPage } = useConfigContext();
  const inputRef = useRef(null);
  const [showMenu, setShowMenu] = useState(false)
  // const availableTickers = ['AAPL', 'AMZN', 'TSLA'];
  const { ticker, setTicker } = useConfigContext();
  const { dbTickers: availableTickers } = useDataContext();
  const [inputValue, setInputValue] = useState(ticker);
  const [suggestions, setSuggestions] = useState([]);
  const [highlightedIndex, setHighlightedIndex] = useState(-1);

  const handleInputChange = (e) => {
    setShowMenu(true);
    const value = e.target.value.toUpperCase();
    setInputValue(value);
    setSuggestions(
      availableTickers.filter((ticker) => ticker.startsWith(value))
    );
    setHighlightedIndex(-1);
  };

  const handleInputKeyDown = (e) => {
    const { key } = e;
    if (key === 'ArrowDown') {
      setHighlightedIndex((prevIndex) => (prevIndex + 1) % suggestions.length);
    } else if (key === 'ArrowUp') {
      setHighlightedIndex(
        (prevIndex) => (prevIndex - 1 + suggestions.length) % suggestions.length
      );
    } else if (key === 'Enter') {
      if (highlightedIndex !== -1) {
        setInputValue(suggestions[highlightedIndex]);
        setTicker(suggestions[highlightedIndex]);
        setSuggestions([]);
        if (appPage !== 'ticker') setAppPage('ticker');
      } else if (suggestions.includes(inputValue)) {
        setTicker(inputValue);
        setSuggestions([]);
        //remove focus on input
        e.target.blur();
        if (appPage !== 'ticker') setAppPage('ticker');
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    setInputValue(suggestion);
    setTicker(suggestion);
    setSuggestions([]);
    if (appPage !== 'ticker') setAppPage('ticker');
  };

  const handleSuggestionHover = (index) => {
    setHighlightedIndex(index);
  };

  return (
    <Wrapper ref={inputRef}>
      <OutsideClickCloseMenu inputRef={inputRef} setShowMenu={setShowMenu}>
        <input
          type='text'
          value={inputValue}
          onClick={() => setShowMenu(true)}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyDown}
          placeholder={'Enter a ticker'}
        />
        {showMenu && suggestions.length > 0 && (
          <ul>
            {suggestions.map((suggestion, index) => (
              <li
                key={index}
                onClick={() => handleSuggestionClick(suggestion)}
                onMouseEnter={() => handleSuggestionHover(index)}
                className={index === highlightedIndex ? 'highlighted' : ''}
              >
                {suggestion}
              </li>
            ))}
          </ul>
        )}
      </OutsideClickCloseMenu>
    </Wrapper>
  );
};

export default TickerInput;
