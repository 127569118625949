import styled from 'styled-components';
import { useState } from 'react';
import Chart from '../visuals/Chart';

const Wrapper = styled.div`
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 15rem;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  .chart {
    border: solid 1px grey;
    grid-column: 1;
    grid-row: 1 / 4;
  }
  .tickerInfo {
    border: solid 1px red;
    grid-column: 1;
    grid-row: 4/6;
  }
  .watchlists {
    border: solid 1px blue;
    grid-column: 2;
    grid-row: 1 / 6;
  }
`;

const Watchlists = () => {

  const [ticker, setTicker] = useState(null);

  
  return (
    <Wrapper>
      {/* <div className='chart'>Chart</div> */}
      <Chart className='chart' tickers={[ticker]} />
      <div className='tickerInfo'>TickerInfo</div>
      <div className='watchlists'>Watchlists</div>
    </Wrapper>
  );
};
export default Watchlists;
