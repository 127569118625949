const parseTimeInterval = (interval) => {
  let multiplier;
  if (interval.length === 1) {
    multiplier = 1;
  } else {
    multiplier = parseInt(interval.slice(0, -1));
  }

  let timespan = interval.slice(-1);
  if (timespan === 'm') {
    timespan = 'minute';
  } else if (timespan === 'D') {
    timespan = 'day';
  } else if (timespan === 'W') {
    timespan = 'week';
  } else if (timespan === 'M') {
    timespan = 'month';
  }

  const results = {
    multiplier: multiplier,
    timespan: timespan,
  }
  return results;
}

export default parseTimeInterval;