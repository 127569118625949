import styled from 'styled-components';
import { useAuth0 } from '@auth0/auth0-react';

const Wrapper = styled.div`
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  .indexPageContainer {
    grid-column: 1;
    grid-row: 1;
  }
`;

const IndexPage = () => {
  const { loginWithRedirect } = useAuth0();

  return (
    <Wrapper>
      <div className='indexPageContainer' onClick={() => loginWithRedirect({
        redirectUri: process.env.REACT_APP_URL
      })}>
        Click here to login
      </div>
    </Wrapper>
  );
};
export default IndexPage;
