import { useEffect, useState } from 'react';
import marketStatus from '../../utils/marketStatus/marketStatus';

const Lag = ({ latestTimestamp, setIsLag }) => {
  const [lag, setLag] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const status = marketStatus(latestTimestamp);
      if (status !== 'closed') {
        const tempLag = new Date().getTime() - latestTimestamp;
        setLag(tempLag);
        if (tempLag > 5000) setIsLag(true);
        else setIsLag(false);
      }
    }, 100);
    return () => clearInterval(intervalId);
  }, [latestTimestamp]);

  // return <div>{lag > 5000 ? ' lag: >' + lag + 'ms' : ''}</div>;
  return <div></div>;
};
export default Lag