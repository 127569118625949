import styled from 'styled-components';
import { useEffect, useState } from 'react';

const Wrapper = styled.div`
  .tickers {
    display: flex;
    /* justify-content: space-around; */
    flex-wrap: wrap;
  }
  .ticker {
    background-color: white;
    padding: 0.25rem 0.25rem;
    padding-bottom: 0.1rem;
    margin: 0rem 0.5rem;
    font-size: 0.8rem;
  }
  .tickerInfo {
    display: ${(props) => props.descriptionDisplay};
    margin: 0rem 0.5rem;
    margin-top: 0.25rem;
  }
  .tickerName {
    background-color: white;
    width: fit-content;
    font-size: 1rem;
    padding: 0.25rem 0.25rem;
  }
  .tickerDetails {
    background-color: white;
    width: fit-content;
    font-size: 0.8rem;
    padding: 0.25rem 0.25rem;
  }
  .tickerMarketCap {
    background-color: white;
    width: fit-content;
    font-size: 0.8rem;
    padding: 0.25rem 0.25rem;
  }
  .tickerDescription {
    background-color: white;
    width: 20rem;
    max-height: 7rem;
    overflow: scroll;
    font-size: 0.8rem;
    padding: 0.25rem 0.25rem;
  }
  ${(props) => props.css}
`;

const MultiTickerLegend = ({
  slotId,
  widgetId,
  tickers,
  renderData,
  freezeTicker,
  setFreezeTicker,
}) => {
  // console.log('Legend');
  // const ticker = tickers[0]
  // const tickerRenderData = renderData[ticker]

  const [showDescription, setShowDescription] = useState(false);
  const [detailsTicker, setDetailsTicker] = useState();
  let descriptionDisplay = 'none';
  if (showDescription) {
    descriptionDisplay = 'inline-block';
  }

  useEffect(() => {
    if (!tickers.includes(detailsTicker)) {
      setShowDescription(false);
      setDetailsTicker(null);
    }
  }, [tickers])
  

  let css = '';
  tickers.forEach((ticker) => {
    css += `.${ticker.toLowerCase()} { border-bottom: solid 1px ${renderData[ticker].hexColor.vibrant}; } `;
  })

  return (
    <Wrapper css={css} descriptionDisplay={descriptionDisplay}>
      <div className='tickers'>
        {tickers.map((ticker) => {
          return (
            <div
              key={`slot${slotId}widget${widgetId}multiTickerChart${ticker}`}
              className={`ticker ${ticker.toLowerCase()} noselect`}
              onClick={() => {
                setDetailsTicker(ticker);
                if (!showDescription || ticker !== detailsTicker) {
                  setShowDescription(true);
                } else {
                  setShowDescription(false);
                }
              }}
            >
              {ticker}&nbsp;&nbsp;<span>{renderData[ticker].last}</span>
            </div>
          );
        })}
      </div>
      {detailsTicker && (
        <div className='tickerInfo'>
          {renderData[detailsTicker].name && (
            <div className='tickerName'>{renderData[detailsTicker].name}</div>
          )}
          {renderData[detailsTicker].sector &&
          renderData[detailsTicker].industry ? (
            <div className='tickerDetails'>
              {renderData[detailsTicker].sector + ' '}&bull;
              {' ' + renderData[detailsTicker].industry}
            </div>
          ) : (
            <div className='tickerDetails'>{renderData[detailsTicker].sic}</div>
          )}
          {renderData[detailsTicker].marketCap && (
            <div className='tickerMarketCap'>
              {'Market Cap: ' + renderData[detailsTicker].marketCap}
            </div>
          )}
          {renderData[detailsTicker].description && (
            <div className='tickerDescription'>
              {renderData[detailsTicker].description}
            </div>
          )}
        </div>
      )}
    </Wrapper>
  );
};
export default MultiTickerLegend;
