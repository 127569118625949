
import styled from 'styled-components';
import Widget from './Widget'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
`;

const Slot = ({ slotId, slotConfig, slotWidgetConfigs, slotVisualsConfigs, slotTickers, ...props }) => {
  // console.log('Slot')

  return (
    <Wrapper>
      {slotWidgetConfigs.map((widgetConfigs, i) => {
        return (
          <Widget
            key={'slot' + slotId + 'widget' + i}
            className={'slot' + slotId + 'widget' + i}
            slotId={slotId}
            widgetId={i}
            widgetConfigs={widgetConfigs}
            widgetVisualConfigs={slotVisualsConfigs[i]}
            widgetTickers={slotTickers[i]}
            renderData={props.renderData}
            currentHlocv={props.currentHlocv}
            latestTimestamp={props.latestTimestamp}
          />
        );
      })}
    </Wrapper>
  );
};
export default Slot;