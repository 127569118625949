import styled from 'styled-components';
import Chart from '../visuals/Chart';
import { useConfigContext } from '../context/config/configContext';

const Wrapper = styled.div`
  height: 20rem;
  width: 60rem;
  border: solid 1px grey;
`;

const ChartContainer = ({ latestTimestamp }) => {
  const { ticker, tickerChartConfig } = useConfigContext();

  //need to edit chart to accept ticker details and currentHlocv
  //also lastEodHlocv & calendarTimeframes from initialization data
  //so that it can work with either renderData or tickerDetails
  //...so much data that I should likely be able to split off a ticker
  //into full slice of render data for one ticker

  return (
    <Wrapper>
      <Chart
        tickers={[ticker]} //array of tickers
        slotId={1} //slotId integer
        widgetId={1} //widgetId integer
        extendedSession={tickerChartConfig.extendedSession} //extendedSession boolean
        showVolume={tickerChartConfig.showVolume} //showVolume boolean
        handleScale={tickerChartConfig.timeScroll} //handleScale/timeScroll boolean
        handleScroll={tickerChartConfig.priceScroll} //handleScroll/priceScroll boolean
        defaultTimeInterval={tickerChartConfig.defaultTimeInterval} //defaultTimeInterval string ex: '1m'
        timeIntervals={tickerChartConfig.timeIntervals} //array of timeInterval obj (name string & visible range integer)
        defaultCalendarTimeframe={tickerChartConfig.defaultCalendarTimeframe} //timeframe string ex: 'D'
        calendarTimeframes={tickerChartConfig.calendarTimeframes} //array of timeframes ex: ['D', 'W', 'M', 'Q', 'Y']
        renderData={''} //renderData object
        latestTimestamp={latestTimestamp} //latestTimestamp integer
      />
    </Wrapper>
  );
};
export default ChartContainer;
