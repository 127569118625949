import styled from 'styled-components';
import { useState, useEffect, useRef } from 'react';
import { createChart } from 'lightweight-charts';
import marketStatus from '../../../utils/marketStatus/marketStatus';
import { timeToLocal } from '../../../utils/marketStatus/dateTimeFunctions';
import { getMinuteOfSession } from '../../../utils/marketStatus/marketDates';
import CandleTimeframes from './CandleTimeframes';
import Session from './Session';
import Legend from './Legend';
import Stats from './Stats';
import CandleTimeRemaining from './CandleTimeRemaining';
import parseTimeInterval from './parseTimeInterval';
import getStartDate from './getStartDate';
import getVisibleRange from './getVisibleRange';
import extSessionAreaSeries from './extSessionAreaSeries';
import chartConfig from './chartConfig';
import timescaleConfig from './timescaleConfig';
import updatePriceline from './updatePriceline';
import { useApiContext } from '../../context/api/apiContext';
import setTimescale from './setTimescale';

//need to change all values to pixels
//...since this is how lightweight charts scales
const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  .chart {
    height: 100%;
    width: 100%;
  }
  /* .chart div table tr td:nth-child(3) div {
    width: 500px;
  } */
  .legendContainer {
    height: 0px;
    position: relative;
    color: black;
    left: 0.3rem;
    top: 0.5rem;
    z-index: 3;
    font-size: 1rem;
    font-weight: 300;
  }
  .legend {
    width: fit-content;
    /* background-color: white; */
  }
  .statsContainer {
    height: 0px;
    position: relative;
    color: black;
    right: 1rem;
    top: 0.5rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .stats {
    width: fit-content;
    margin-right: 4rem;
    margin-left: auto;
  }
  .candleTimeframesContainer {
    height: 0px;
    width: fit-content;
    position: relative;
    color: black;
    left: 0.3rem;
    bottom: 3.2rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .candleTimeframes {
    width: fit-content;
    background-color: white;
    border: solid 1px black;
  }
  .sessionContainer {
    height: 0px;
    position: relative;
    color: black;
    left: 0.3rem;
    bottom: 4.6rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .session {
    width: fit-content;
  }
  .candleTimeRemainingContainer {
    height: 0px;
    position: relative;
    color: black;
    right: 0rem;
    bottom: 1.1rem;
    z-index: 3;
    font-size: 12px;
    font-weight: 300;
  }
  .candleTimeRemaining {
    width: fit-content;
    margin-right: 10px;
    margin-left: auto;
    background-color: white;
    /* border: solid 1px black; */
  }
`;

//To Do
//explore functions vs useEffect
//nextCandleTime for cases of skipped candles
//add volume back
//better handle visible range
//and possibly logic on number of how many candles to request

const SingleTickerChart = ({
  setLoading,
  currentHlocv,
  handleScale,
  handleScroll,
  showVolume,
  defaultTimeInterval,
  timeIntervals,
  ...props
}) => {
  const { getTimeSeries } = useApiContext();

  // console.log('Single Ticker Chart');
  const [ticker, setTicker] = useState(props.ticker);
  const [freezeTicker, setFreezeTicker] = useState(false);

  useEffect(() => {
    if (freezeTicker) return;
    if (ticker[0] !== props.ticker[0]) setTicker(props.ticker);
  }, [props.ticker]);

  // console.log('SingleTickerChart');
  const mode = 'Logarithmic';
  // const mode = 'Percentage';

  //variables
  const ref = useRef();
  const [newTicker, setNewTicker] = useState(ticker);
  const [rawTimeSeries, setRawTimeSeries] = useState();
  const [initialtimeSeries, setInitialTimeSeries] = useState();
  const [initialVolumeTimeSeries, setInitialVolumeTimeSeries] = useState();
  const [premarketVolume, setPremarketVolume] = useState(0);
  const [lastCandle, setLastCandle] = useState();
  const [areaPre, setAreaPre] = useState();
  const [areaPost, setAreaPost] = useState();
  const [volumeSticks, setVolumeSticks] = useState();
  const [candles, setCandles] = useState();
  const [timeSeriesAreaPre, setTimeSeriesAreaPre] = useState();
  const [timeSeriesAreaPost, setTimeSeriesAreaPost] = useState();
  const [timeSeriesVolume, setTimeSeriesVolume] = useState();
  const [timeSeries, setTimeSeries] = useState();
  const [priceLine, setpriceLine] = useState();
  const [timeframe, setTimeframe] = useState(() => {
    const obj = parseTimeInterval(defaultTimeInterval);
    return {
      multiplier: obj.multiplier,
      timespan: obj.timespan,
      startDate: getStartDate(obj),
    };
  });
  const [extendedSession, setExtendedSession] = useState(props.extendedSession);
  const [activeTimeframe, setActiveTimeframe] = useState();
  const [displayTimeframeOptions, setDisplayTimeframeOptions] = useState(false);
  const [displayTime, setDisplayTime] = useState(() => {
    if (timeframe.timespan === 'minute') return true;
    return false;
  });
  const [lastHlocv, setLastHlocv] = useState();
  const [hoverHlocv, setHoverHlocv] = useState();

  // fetch timeseries data for new ticker and/or timeframe
  useEffect(() => {
    (async () => {
      const data = await getTimeSeries(ticker, timeframe);
      setLastCandle(null);
      setRawTimeSeries(data);
    })();
  }, [ticker, timeframe]);

  // initialize data to render a new ticker chart
  useEffect(() => {
    if (rawTimeSeries) {
      // create session price and volume arrays
      // filter out ext session candles for open session w/ x min candles
      let sessionPriceData = [];
      let sessionVolumeData = [];
      if (!extendedSession && timeframe.timespan === 'minute') {
        let premarketVolumeCounter = 0;
        rawTimeSeries.forEach((el) => {
          if (marketStatus(el.t) === 'open') {
            sessionPriceData.push(el);
            sessionVolumeData.push({
              t: el.t,
              time: el.time,
              value: el.v,
            });
          } else if (marketStatus(el.t) === 'premarket') {
            premarketVolumeCounter += el.v;
          }
        });
        setPremarketVolume(premarketVolumeCounter);
      } else {
        sessionPriceData = rawTimeSeries;
        rawTimeSeries.forEach((el) => {
          sessionVolumeData.push({
            t: el.t,
            time: el.time,
            value: el.v,
          });
        });
      }
      // set state - timeframe, display time, price and volume arrays
      setActiveTimeframe(timeframe.timespan);
      if (timeframe.timespan === 'minute') {
        setDisplayTime(true);
      } else setDisplayTime(false);
      setInitialVolumeTimeSeries(sessionVolumeData);
      setInitialTimeSeries(sessionPriceData);
      setTimeSeries(sessionPriceData);
      // get color for lastHloc and set lastHlocv
      const lastHloc = sessionPriceData[sessionPriceData.length - 1];
      const lastVolume = sessionVolumeData[sessionVolumeData.length - 1];
      const secondLastHloc = sessionPriceData[sessionPriceData.length - 2];
      let hlocvColor = 'grey';
      if (lastHloc && secondLastHloc) {
        if (lastHloc.close > secondLastHloc.close) {
          hlocvColor = '#26A69A';
        } else if (lastHloc.close < secondLastHloc.close) {
          hlocvColor = '#EF5350';
        }
      }
      setLastHlocv({
        h: lastHloc.high,
        l: lastHloc.low,
        o: lastHloc.open,
        c: lastHloc.close,
        v: lastVolume !== null ? lastVolume.value : null,
        color: hlocvColor,
      });
      // once all data is ready, set new ticker so chart can render
      setNewTicker(ticker);
    }
  }, [rawTimeSeries, showVolume, extendedSession]);

  // create chart with timeseries, pre/post area background, hover, and visible range
  useEffect(() => {
    let chart;
    if (initialtimeSeries) {
      chart = createChart(ref.current);
      // set basic chart congfigurations for interaction, colors, and margins
      chartConfig(chart, mode, handleScale, handleScroll, extendedSession, activeTimeframe);
      // set both ext session shaded area time series
      extSessionAreaSeries(
        chart,
        initialtimeSeries,
        extendedSession,
        activeTimeframe,
        setTimeSeriesAreaPre,
        setAreaPre,
        setTimeSeriesAreaPost,
        setAreaPost
      );
      // set sticks with volume data
      // - not sure why I chose not to display ext session volume
      let volumeSeries;
      if ((showVolume && initialVolumeTimeSeries && !extendedSession) || activeTimeframe !== 'minute') {
        volumeSeries = chart.addHistogramSeries({
          priceFormat: {
            type: 'volume',
          },
          color: 'rgb(33, 151, 243)',
          priceScaleId: 'left',
          priceLineVisible: false,
        });
        volumeSeries.setData(initialVolumeTimeSeries);
        setTimeSeriesVolume(initialVolumeTimeSeries);
        setVolumeSticks(volumeSeries);
      }
      // **
      // add indicators here
      // **
      // set sticks with price data
      let candlestickSeries = chart.addCandlestickSeries();
      candlestickSeries.setData(initialtimeSeries);
      // customize visible range
      // const { rightOffset } = getVisibleRange(chart, initialtimeSeries, timeIntervals, timeframe);
      // timescaleConfig(chart, rightOffset, displayTime);
      setTimescale(chart, initialtimeSeries, timeIntervals, timeframe, displayTime);
      // set candles to be read by chart
      setCandles(candlestickSeries);
      // subscribe to crosshair and set hover candle data
      chart.subscribeCrosshairMove((param) => {
        let hloc;
        if (candlestickSeries) {
          hloc = param.seriesPrices.get(candlestickSeries);
          // console.log(param.time)
          //to look up color, need to map colors to times
          //when initially loading the array
        }
        let volume;
        if (volumeSeries) {
          volume = param.seriesPrices.get(volumeSeries);
        }
        if (hloc) {
          setHoverHlocv({
            h: hloc.high,
            l: hloc.low,
            o: hloc.high,
            c: hloc.high,
            v: volume !== null ? volume : null,
          });
        } else setHoverHlocv(null);
      });
      // set loading false, chart now ready to be displayed
      setLoading(false);
      // clear chart on unmount
      return () => {
        chart.removeSeries(candlestickSeries);
        if (volumeSeries) {
          chart.removeSeries(volumeSeries);
        }
        chart.remove();
      };
    }
  }, [initialtimeSeries, displayTime]);

  // set last candle based on currentHlocv
  useEffect(() => {
    if (ticker === newTicker) {
      if (ticker in currentHlocv) {
        setLastCandle(currentHlocv[ticker]);
      }
    }
  }, [currentHlocv]);

  // update timeseries, pre/post area background, and priceline
  useEffect(() => {
    if (timeSeries && candles && lastCandle) {
      // update price line for pre/post when not displaying ext session
      const statusNow = marketStatus(lastCandle.t);
      updatePriceline(statusNow, extendedSession, activeTimeframe, priceLine, candles, lastCandle.c, setpriceLine);
      // update timeseries & pre/post background
      if (statusNow === 'open' || extendedSession) {
        // remove priceline if not relevant
        if (priceLine) {
          candles.removePriceLine(priceLine);
        }
        // if market open & dwm timeframe, simply update last candle
        // - will never need to create new candle, force refresh full site overnight
        if (statusNow === 'open') {
          if (activeTimeframe === 'day' || activeTimeframe === 'week' || activeTimeframe === 'month') {
            candles.update({
              time: timeSeries[timeSeries.length - 1].time,
              open: timeSeries[timeSeries.length - 1].open,
              high: Math.max(timeSeries[timeSeries.length - 1].high, lastCandle.h),
              low: Math.min(timeSeries[timeSeries.length - 1].low, lastCandle.l),
              close: lastCandle.c,
            });
            // const volumeUpdate = {
            //   time: timeSeriesVolume[timeSeriesVolume.length - 1].time,
            //   value: timeSeriesVolume[timeSeriesVolume.length - 1].value + lastCandle.v,
            // };
            // volumeSticks.update(volumeUpdate);
          }
        }
        // if min timeframe, need to either update last candle or create new
        if (activeTimeframe === 'minute') {
          const interval = timeframe.multiplier;
          const oldTimestamp = timeSeries[timeSeries.length - 1].t;
          const oldTime = new Date(oldTimestamp);
          const oldCandleStartTime = Math.floor(getMinuteOfSession(oldTimestamp) / interval) * interval;
          const newTime = new Date(lastCandle.t);
          const newCandleStartTime = Math.floor(getMinuteOfSession(lastCandle.t) / interval) * interval;
          // update last candle if still in last candle timespan
          if (oldCandleStartTime === newCandleStartTime) {
            const updatedMinuteHlocv = {
              t: lastCandle.t,
              time: timeSeries[timeSeries.length - 1].time,
              open: timeSeries[timeSeries.length - 1].open,
              high: Math.max(timeSeries[timeSeries.length - 1].high, lastCandle.c),
              low: Math.min(timeSeries[timeSeries.length - 1].low, lastCandle.c),
              close: lastCandle.c,
            };
            let updatedTimeSeries = timeSeries;
            updatedTimeSeries[timeSeries.length - 1] = updatedMinuteHlocv;
            setTimeSeries(updatedTimeSeries);
            candles.update(updatedMinuteHlocv);
            // const currentMarketSession = marketStatus(lastCandle.t);
            // const currentDate = newTime.getUTCDate();
            // let accumulatedVolume = 0;
            // for (let i = timeSeriesVolume.length - 1; i >= 0; i--) {
            //   const histTimestamp = timeSeriesVolume[i].t;
            //   const histDate = new Date(histTimestamp).getUTCDate();
            //   if (
            //     marketStatus(histTimestamp) !== currentMarketSession ||
            //     histDate !== currentDate
            //   ) {
            //     break;
            //   } else {
            //     console.log(
            //       new Date(histTimestamp).toISOString(),
            //       timeSeriesVolume[i].value
            //     );
            //     accumulatedVolume += timeSeriesVolume[i].value;
            //   }
            // }
            // const addedVolume = lastCandle.v - accumulatedVolume;
            // const currentCandleVolume =
            //   timeSeriesVolume[timeSeriesVolume.length - 1].value + addedVolume;
            // console.log('full day av', lastCandle.v)
            // console.log('preloaded av', accumulatedVolume)
            // console.log('added volume', addedVolume)
            // console.log('current volume', currentCandleVolume)
            // if (showVolume) {
            //   const volumeUpdate = {
            //     time: timeSeriesVolume[timeSeriesVolume.length - 1].time,
            //     value: currentCandleVolume,
            //   };
            //   volumeSticks.update(volumeUpdate);
            // }
            // create new candle if entering new timespan
          } else if (newTime > oldTime) {
            // *** FIX ***
            // Needs to account for skipped candles
            const nextCandleTime = timeToLocal(oldTime.getTime() + interval * 60 * 1000);
            // Fix previous issues wirh timeSeriesAreaPre being undefined
            if (extendedSession && timeSeriesAreaPre && timeSeriesAreaPost) {
              const newMinutePre = {
                time: nextCandleTime,
                value: marketStatus(lastCandle.t) === 'premarket' ? 1000000 : 0,
              };
              let updatedTimeSeriesAreaPre = timeSeriesAreaPre;
              updatedTimeSeriesAreaPre.push(newMinutePre);
              setTimeSeriesAreaPre(updatedTimeSeriesAreaPre);
              areaPre.update(newMinutePre);
              const newMinutePost = {
                time: nextCandleTime,
                value: marketStatus(lastCandle.t) === 'postmarket' ? 1000000 : 0,
              };
              let updatedTimeSeriesAreaPost = timeSeriesAreaPost;
              updatedTimeSeriesAreaPost.push(newMinutePost);
              setTimeSeriesAreaPost(updatedTimeSeriesAreaPost);
              areaPost.update(newMinutePost);
            }
            // Create new candle and add to existing candle series
            const newMinuteHlocv = {
              t: lastCandle.t,
              time: nextCandleTime,
              open: lastCandle.c,
              high: lastCandle.c,
              low: lastCandle.c,
              close: lastCandle.c,
            };
            let updatedTimeSeries = timeSeries;
            updatedTimeSeries.push(newMinuteHlocv);
            setTimeSeries(updatedTimeSeries);
            candles.update(newMinuteHlocv);
            // if (showVolume) {
            //   const volumeUpdate = {
            //     time: nextTime,
            //     value: lastCandle.v,
            //   };
            //   volumeSticks.update(volumeUpdate);
            // }
          }
        }
      }
      // const lastHloc = timeSeries[timeSeries.length-1];
      // const lastVolume = volumeSticks[volumeSticks.length-1];
      // setLastHlocv({
      //   h: lastHloc.high,
      //   l: lastHloc.low,
      //   o: lastHloc.open,
      //   c: lastHloc.close,
      //   v: lastVolume ? lastVolume.value : null,
      // })
      return () => {
        setpriceLine(null);
      };
    }
  }, [lastCandle, candles, timeSeries]);

  return (
    <Wrapper>
      <div className='statsContainer'>
        <div className='stats'>
          <Stats
            ticker={newTicker}
            lastHlocv={lastHlocv}
            hoverHlocv={hoverHlocv}
          />
        </div>
      </div>
      <div className='legendContainer'>
        <div className='legend'>
          <Legend
            ticker={newTicker}
            freezeTicker={freezeTicker}
            setFreezeTicker={setFreezeTicker}
          />
        </div>
      </div>
      <div className='chart' ref={ref} />
      <div className='candleTimeframesContainer'>
        <div className='candleTimeframes'>
          <CandleTimeframes
            setTimeframe={setTimeframe}
            setDisplayTime={setDisplayTime}
            timeframe={timeframe}
            timeIntervals={timeIntervals}
            extendedSession={extendedSession}
            slotId={props.slotId}
            widgetId={props.widgetId}
            displayTimeframeOptions={displayTimeframeOptions}
            setDisplayTimeframeOptions={setDisplayTimeframeOptions}
            setLoading={setLoading}
          />
        </div>
      </div>
      {activeTimeframe === 'minute' && (
        <div className='sessionContainer'>
          {timeframe.multiplier <= 30 && (
            <div className='session'>
              {displayTimeframeOptions && (
                <Session
                  extendedSession={extendedSession}
                  setExtendedSession={setExtendedSession}
                  setLoading={setLoading}
                />
              )}
            </div>
          )}
        </div>
      )}
      <div className='candleTimeRemainingContainer'>
        <div className='candleTimeRemaining'>
          <CandleTimeRemaining
            timeframe={timeframe}
            extendedSession={extendedSession}
          />
        </div>
      </div>
    </Wrapper>
  );
};
export default SingleTickerChart;
