import { useState } from 'react';
import styled from 'styled-components';
import { useConfigContext } from '../../context/config/configContext';
import { useUserContext } from '../../context/user/userContext';

const Wrapper = styled.div`
  .buttonContainer {
    display: flex;
    flex-direction: row;
  }
`;

const EditChart = ({
  chartConfig,
  setChartConfig,
  previewChart,
  setPreviewChart,
  setPage,
}) => {
  const { isLoading } = useUserContext();
  const { addConfig, editConfig } = useConfigContext();

  const [saveMessage, setSaveMessage] = useState('');
  const handleSave = async () => {
    let res;
    console.log('settings', chartConfig._id);
    if (chartConfig._id) {
      res = await editConfig('chart', chartConfig);
    } else {
      res = await addConfig('chart', chartConfig);
    }
    setSaveMessage(res.msg);
    if (res.status === 1) {
      setPage('view');
    }
  };

  return (
    <Wrapper>
      {isLoading && <div>Loading...</div>}
      {!isLoading && (
        <div className='buttonContainer'>
          <button onClick={() => setPage('view')}>Cancel</button>
          <button onClick={() => handleSave()}>Save</button>
          <div>{saveMessage}</div>
        </div>
      )}
      <div>
        <label htmlFor='name'>Chart Name:</label>
        <input
          type='text'
          id='name'
          value={chartConfig.name}
          onChange={(e) => {
            setChartConfig({ ...chartConfig, name: e.target.value });
          }}
        />
      </div>
      <div>
        <label htmlFor='extendedSession'>Extended Session:</label>
        <input
          type='checkbox'
          id='extendedSession'
          checked={chartConfig.extendedSession}
          onChange={() => {
            setChartConfig({
              ...chartConfig,
              extendedSession: !chartConfig.extendedSession,
            });
          }}
        />
      </div>
      <div>
        <label htmlFor='showVolume'>Show Volume:</label>
        <input
          type='checkbox'
          id='showVolume'
          checked={chartConfig.showVolume}
          onChange={() => {
            setChartConfig({
              ...chartConfig,
              showVolume: !chartConfig.showVolume,
            });
          }}
        />
      </div>
      <div>
        <label htmlFor='timeScroll'>Time Scroll:</label>
        <input
          type='checkbox'
          id='timeScroll'
          checked={chartConfig.timeScroll}
          onChange={() => {
            setChartConfig({
              ...chartConfig,
              timeScroll: !chartConfig.timeScroll,
            });
          }}
        />
      </div>
      <div>
        <label htmlFor='priceScroll'>Price Scroll:</label>
        <input
          type='checkbox'
          id='priceScroll'
          checked={chartConfig.priceScroll}
          onChange={() => {
            setChartConfig({
              ...chartConfig,
              priceScroll: !chartConfig.priceScroll,
            });
          }}
        />
      </div>
      <div>
        <label htmlFor='defaultTimeInterval'>Default Time Interval:</label>
        <select
          id='defaultTimeInterval'
          value={chartConfig.defaultTimeInterval}
          onChange={(e) => {
            setChartConfig({
              ...chartConfig,
              defaultTimeInterval: e.target.value,
            });
          }}
        >
          <option value='1m'>1 Minute</option>
          <option value='5m'>5 Minute</option>
          <option value='10m'>10 Minute</option>
          <option value='15m'>15 Minute</option>
          <option value='30m'>30 Minute</option>
          <option value='65m'>65 Minute</option>
          <option value='195m'>195 Minute</option>
          <option value='D'>Day</option>
          <option value='W'>Week</option>
          <option value='M'>Month</option>
        </select>
      </div>
      <div>
        <label htmlFor='previewChart'>Preview Chart:</label>
        <input
          type='checkbox'
          id='previewChart'
          checked={previewChart}
          onChange={() => {
            setPreviewChart(!previewChart);
          }}
        />
      </div>
    </Wrapper>
  );
};
export default EditChart;
